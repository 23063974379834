import React from "react";
// Customizable Area Start
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  InputAdornment,
  Typography,
  TextField,
  Stack,
  Dialog,
  Badge
} from "@mui/material";
import { styled } from "@mui/material/styles";

import ApplicationsConversationController from "../src/ApplicationsConversationController.web";
import { ChatIconSuccess, EmojiIcon } from "./assets";
export const configJSON = require("./config");
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import AttachFileSharpIcon from '@mui/icons-material/AttachFileSharp';
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react';
import SendIcon from '@mui/icons-material/Send';
import moment from "moment";
import { IconButton } from "@material-ui/core";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Bounce, ToastContainer } from "react-toastify";
// Customizable Area End

export default class ApplicationsConversation extends ApplicationsConversationController {
  // Customizable Area Start

  DeleteModel = () => {
    return (
      <DeleteDialogStyle
        open={this.state.DeleteModel}
        onClose={this.DelteModelOpenAndClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to delete this {this.state.IsDeleteReplyOrComment === "Reply" ? "Reply" : "comment"}?
          </Typography>
          <Box className="ButtonContainer">
            <Button variant="contained" data-test-id="DeleteClick" size="small" className="YesButton" onClick={this.DeleteFuncation} >Yes</Button>
            <Button variant="contained" data-test-id="CancelDeleteClick" size="small" className="NoButton" onClick={this.DelteModelOpenAndClose}>No</Button>
          </Box>
        </Box>
      </DeleteDialogStyle>
    )
  }

  EditTextBoxModel = () => {
    const EditButtonDisabled = this.state.EditTextBoxValue === "" && this.state.ReplyAttchmentFiles.length === 0
    return (
      <EditTextBoxModelStyle
        open={this.state.EditMainMessegeModel}
        onClose={this.HandeCloseEditMainMessegeModel}
        maxWidth="md"
        fullWidth
      >
        <Box style={{ width: "100%", position: "relative", marginTop: "10px", marginBottom: "10px" }}>
          <Box>
            <TextField
              type="text"
              data-test-id={"EditReplyTextField"}
              placeholder={"Write your comment..."}
              onChange={(e) => {
                this.EditReplyTextFieldOnChange(e)
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#CBD5E1", // Border color on focus
                  },
                  "& fieldset": {
                    borderColor: "#CBD5E1", // Default border color
                  },
                  "&:hover fieldset": {
                    borderColor: "#CBD5E1", // Border color on hover
                  },
                },
              }}
              multiline
              value={this.state.EditTextBoxValue}
              name={"FirstName"}
              className="inputField"
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <Box style={{ display: "flex", gap: "5px" }} >
                    <InputAdornment position="start" htmlFor="EditUpload" component="label">
                      <Badge badgeContent={this.state.ReplyAttchmentFiles?.length} color="primary">
                        <AttachFileSharpIcon />
                      </Badge>
                    </InputAdornment>
                    <InputAdornment position="start">
                      <img style={{ cursor: "pointer" }} onClick={this.OpenAndCloseEmojiInEditModel} data-test-id={"EditReplyEmojiIcon"} src={EmojiIcon} />
                    </InputAdornment>
                  </Box>
                ),
              }}
            />
          </Box>
          <input type="file" multiple data-test-id={"EditReplyUploadAttchmentFiles"} onChange={(e) => this.ReplyUploadAttchmentFiles(e)} id="EditUpload" style={{ display: "none" }} />
          {
            this.state.EditEmojiModelState &&
            <Box style={{ zIndex: 99999 }} >
              <EmojiPicker
                onEmojiClick={(e) => {
                  this.EditReplyEmojiHandelChange(e.emoji)
                }}
                data-test-id={"EditReplyEmojiPicker"}
                emojiStyle={EmojiStyle.GOOGLE}
                open
              />
            </Box>
          }
        </Box>
        {
          Array.isArray(this.state.EditRenderFilesData) && this.state.EditRenderFilesData.length !== 0 &&
          <Grid container spacing={1} alignItems="center">
            {
              this.state.EditRenderFilesData.map((item) => {
                return (
                  <Grid item xs={4} key={item.id}>
                    <Button data-test-id="ViewFilesEdit" size="small" style={{
                      textTransform: "unset"
                    }} fullWidth variant="contained" onClick={() => {
                      window.open(item.file_url, "_blank")
                    }}>
                      <Typography variant="h6" noWrap style={{ fontSize: "12px", color: "white", }}>
                        View {item.filename}
                      </Typography>
                    </Button>
                  </Grid>
                )
              })
            }
          </Grid>
        }
        <Box className="ButtonContainer">
          <Button variant="contained" data-test-id="EditSaveButton" disabled={EditButtonDisabled} size="small" className="EditYesButton" onClick={this.EditSaveButton}> {this.state.IsItReplyOrChatEdit === "CreateReply" ? "Reply" : "Edit"}</Button>
          <Button variant="contained" size="small" data-test-id="HandeCloseEditMainMessegeModel" className="EditNoButton" onClick={this.HandeCloseEditMainMessegeModel}>Cancel</Button>
        </Box>
      </EditTextBoxModelStyle>
    )
  }

  showErrorToast = () => {
    return (
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        theme="colored"
        transition={Bounce}
      />
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      {this.props.indexValue==2 && <ApplicationsConversationStyle>
        {this.showErrorToast()}
        {this.DeleteModel()}
        {this.EditTextBoxModel()}
        {
          this.state.ChatData.length > 5 && this.state.LoadMoreCont !== this.state.ChatData.length &&
          <Box className="ShowPreviousCommentsContainer">
            <Button variant="contained"
              component="span"
              data-test-id="handleLoadMore"
              onClick={() => {
                this.handleLoadMore()
              }}
              className="ShowComments">
              Show previous comments
            </Button>
          </Box>
        }
        <Grid container spacing={2} style={{
          padding: "5px 20px", maxHeight: "600px",
          overflowY: "auto",
          width: "100%",
          minHeight: "20%",
        }}>
          {
            this.state.loader &&
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center", height: "100%",
                    minHeight: "200px"
                  }}>
                  <CircularProgress />
                </Box>
              </Grid>
            </Grid>
          }
          {Array.isArray(this.state.ChatData) && this.state.ChatData.length !== 0 && !this.state.loader ? this.state.ChatData.slice(0, this.state.LoadMoreCont).map((data, index) => {
            return (
              <Grid item xs={12} key={data.id + index}>
                <Box style={{ display: "flex", gap: "10px" }}>
                  <Box>
                    <Box>
                      <Avatar src={data.ProfilePic} sx={{ width: 32, height: 32 }} />
                    </Box>
                  </Box>
                  <Box className="IconAndNameContainerWraper" style={{ width: "100%" }} >
                    <Box style={{ display: "flex", justifyContent: "space-between", }} >
                      <Box className="IconAndNameContainer">
                        <Box>
                          <Typography variant="h6" className="BoldHeaing" style={{ textTransform: "capitalize" }}>
                            {data.admin_name}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="h6" className="greyColor">
                            &bull;
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="h6" className="LightColorText" >
                            {moment(data.created_at).format('DD-MMM-YYYY-h:mm A')}
                          </Typography>
                        </Box>
                      </Box>
                      {
                        this.state.userDetails.attributes.first_name + ' ' + this.state.userDetails.attributes.last_name === data.admin_name &&
                        <Stack direction="row" className="DeleteAndEditContainer" spacing={1}>
                          <IconButton data-test-id="EditIconTestIdMainComment" onClick={() => {
                            this.EditMainMessegeModelFuncation(data.comment, "Chat", data.commentId, data.Files)
                          }} className="EditIcon" size="small" aria-label="delete">
                            <EditIcon className="Icon" fontSize="inherit" />
                          </IconButton>
                          <IconButton size="small" data-test-id="DeleteModelOpenFun" onClick={() => {
                            this.DeleteModelOpenFun(data.id, "Chat")
                          }} className="DeleteIcon" aria-label="delete">
                            <DeleteOutlineIcon className="Icon" fontSize="inherit" />
                          </IconButton>
                        </Stack>
                      }
                    </Box>
                    <Box>
                      <Typography variant="h6" style={{ fontSize: "16px" }} className="LightColorText" >
                        {data.comment}
                      </Typography>
                    </Box>
                    {Array.isArray(data.Files) && data.Files.length !== 0 &&
                      <Grid container spacing={1} alignItems="center">
                        {
                          data.Files.map((item) => {
                            return (
                              <Grid item xs={4} key={item.id}>
                                <Button data-test-id="ViewFiles" size="small" style={{
                                  textTransform: "unset"
                                }} fullWidth variant="contained" onClick={() => {
                                  window.open(item.file_url, "_blank")
                                }}>
                                  <Typography variant="h6" noWrap style={{ fontSize: "12px", textTransform: "unset" }}>
                                    View {item.filename}
                                  </Typography>
                                </Button>
                              </Grid>
                            )
                          })
                        }
                      </Grid>
                    }
                    <Box className="ReplyContainer" data-test-id={"ReplyContainerDataTestId"} onClick={() => {
                      this.ReplyButtonHandel(data)
                    }}>
                      <img src={ChatIconSuccess} style={{ height: "12px", width: "12px" }} />
                      <Typography variant="h6" style={{ fontSize: "16px" }} className="SuccessColorText" >
                        {data.conversation_replies.length !== 0 && data.conversation_replies.length} Reply
                      </Typography>
                      {
                        data.conversation_replies.length !== 0 &&
                        <>
                          {this.state.ReplyTextBoxIndex === Number(data.id) ? <ExpandLess /> : <ExpandMore />}
                        </>
                      }
                    </Box>
                    <Collapse in={this.state.ReplyTextBoxIndex === Number(data.id)} timeout="auto" unmountOnExit>
                      {
                        data.conversation_replies?.map((item, indexreply) => {
                          return (
                            <Box key={item.id} style={{ display: "flex", gap: "10px", padding: "10px 0" }} >
                              <Box>
                                <Box>
                                  <Avatar src={item.ReplyByProfilePicture} sx={{ width: 32, height: 32 }} />
                                </Box>
                              </Box>
                              <Box style={{ width: "100%", paddingRight: "20px" }}>
                                <Box style={{ display: "flex", justifyContent: "space-between", }} >
                                  <Box className="IconAndNameContainer">
                                    <Box>
                                      <Typography variant="h6" className="BoldHeaing" style={{ textTransform: "capitalize" }}>
                                        {item.ReplyByName}
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Typography variant="h6" className="greyColor">
                                        &bull;
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Typography variant="h6" className="LightColorText" >
                                        {moment(item.created_at).format('DD-MMM-YYYY-h:mm A')}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  {
                                    item.ReplyByUserType.toLowerCase() === "account" &&
                                    <Stack direction="row" className="DeleteAndEditContainer" spacing={1}>
                                      <IconButton data-test-id="ReplyEditTestId" onClick={() => {
                                        this.EditMainMessegeModelFuncation(item.reply, "Reply", item.id, item.conversation_files)
                                      }} className="EditIcon" size="small" aria-label="delete">
                                        <EditIcon className="Icon" fontSize="inherit" />
                                      </IconButton>
                                      <IconButton size="small"
                                        data-test-id="ReplyDelete"
                                        onClick={() => {
                                          this.DeleteModelOpenFun(item.id, "Reply")
                                        }} className="DeleteIcon" aria-label="delete">
                                        <DeleteOutlineIcon className="Icon" fontSize="inherit" />
                                      </IconButton>
                                    </Stack>
                                  }
                                </Box>
                                <Box>
                                  <Typography variant="h6" style={{ fontSize: "16px" }} className="LightColorText" >
                                    {item.reply}
                                  </Typography>
                                </Box>
                                {
                                  Array.isArray(item.conversation_files) && item.conversation_files.length !== 0 &&
                                  <Grid container spacing={1} alignItems="center">
                                    {
                                      item.conversation_files.map((item) => {
                                        return (
                                          <Grid item xs={4} key={item.id}>
                                            <Button data-test-id="ReplyViewFiles"
                                              style={{
                                                textTransform: "unset"
                                              }}
                                              size="small" fullWidth variant="contained" onClick={() => {
                                                window.open(item.file_url, "_blank")
                                              }}>
                                              <Typography variant="h6" noWrap style={{ fontSize: "12px", textTransform: "unset" }}>
                                                View {item.filename}
                                              </Typography>
                                            </Button>
                                          </Grid>
                                        )
                                      })
                                    }
                                  </Grid>
                                }
                                <Box style={{ width: "100%", position: "relative", marginTop: "10px", marginBottom: "10px" }}>
                                  {
                                    indexreply === data.conversation_replies.length - 1 &&
                                    <Box>
                                      <TextField
                                        type="text"
                                        data-test-id={"ReplyTextField"}
                                        placeholder={"Write your comment..."}
                                        onChange={(e) => {
                                          this.ReplyTextFieldOnChange(e)
                                        }}
                                        sx={{
                                          "& .MuiOutlinedInput-root": {
                                            "&.Mui-focused fieldset": {
                                              borderColor: "#CBD5E1", // Border color on focus
                                            },
                                            "& fieldset": {
                                              borderColor: "#CBD5E1", // Default border color
                                            },
                                            "&:hover fieldset": {
                                              borderColor: "#CBD5E1", // Border color on hover
                                            },
                                          },
                                        }}
                                        multiline
                                        value={this.state.ReplyTextFildevalue}
                                        name={"FirstName"}
                                        className="inputField"
                                        variant="outlined"
                                        fullWidth
                                        InputProps={{
                                          startAdornment: (
                                            <Box style={{ display: "flex", gap: "5px" }} >
                                              <InputAdornment position="start" htmlFor="UploadFlesReply" component="label">
                                                <Badge badgeContent={this.state.ReplyAttchmentFiles?.length} color="primary">
                                                  <AttachFileSharpIcon />
                                                </Badge>
                                              </InputAdornment>
                                              <InputAdornment position="start">
                                                <img onClick={() => this.ReplyEmojiOnClick(item)} data-test-id={"ReplyEmojiIcon"} src={EmojiIcon} />
                                              </InputAdornment>
                                            </Box>
                                          ),
                                          endAdornment: (
                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                              <InputAdornment data-test-id={"ReplyConvertionApiCall"} className={this.state.ReplyTextFildevalue !== "" || this.state.ReplyAttchmentFiles.length !== 0 ? "ColorChangeGreen" : ""} onClick={() => {
                                                this.ReplyConvertionApiCall(data.commentId, data.full_name, this.state.ReplyTextFildevalue)
                                              }} position="start">
                                                <SendIcon />
                                              </InputAdornment>
                                            </Box>
                                          ),
                                        }}
                                      />
                                      <input type="file" multiple data-test-id={"ReplyUploadAttchmentFiles"} onChange={(e) => this.ReplyUploadAttchmentFiles(e)} id="UploadFlesReply" style={{ display: "none" }} />
                                    </Box>
                                  }
                                  {
                                    this.state.EmojiOpenIndex === Number(item.id) &&
                                    <Box style={{ zIndex: 99999, position: "absolute", bottom: "50px" }} >
                                      <EmojiPicker
                                        onEmojiClick={(e) => {
                                          this.ReplyEmojiHandelChange(e.emoji)
                                        }}
                                        data-test-id={"ReplyEmojiPicker"}
                                        emojiStyle={EmojiStyle.GOOGLE}
                                        open
                                      />
                                    </Box>
                                  }
                                </Box>
                              </Box>
                            </Box>
                          )
                        })
                      }
                    </Collapse>
                  </Box>
                </Box>
              </Grid>
            )
          }) :
            <Grid item xs={12}>
              {
                !this.state.loader &&
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", minHeight: "100%" }}>
                  <Typography variant="h6" className="BoldHeaing" style={{ textTransform: "capitalize" }}>
                    No Chat Found
                  </Typography>
                </Box>
              }
            </Grid>
          }
        </Grid>
        <Box style={{ position: "relative", padding: "10px" }}>
          <TextField
            type="text"
            className="inputField"
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#CBD5E1", // Border color on focus
                },
                "& fieldset": {
                  borderColor: "#CBD5E1", // Default border color
                },
                "&:hover fieldset": {
                  borderColor: "#CBD5E1", // Border color on hover
                },
              },
            }}
            data-test-id={"MainReplyTextField"}
            fullWidth
            multiline
            placeholder={"Write your comment..."}
            onChange={(e) => {
              this.MainTextFieldOnChange(e)
            }}
            value={this.state.MainTextFildevalue}
            InputProps={{
              startAdornment: (
                <Box style={{ display: "flex", gap: "5px" }} >
                  <InputAdornment position="start" htmlFor="MainUploadFles" component="label">
                    <Badge badgeContent={this.state.MainAttchmentFiles.length} color="primary">
                      <AttachFileSharpIcon />
                    </Badge>
                  </InputAdornment>
                  <InputAdornment position="start">
                    <img data-test-id="EmojiOpenIndexLast" onClick={() => this.MainTextFieldEmojiOnclick()} src={EmojiIcon} />
                  </InputAdornment>
                </Box>
              ),
              endAdornment: (
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <InputAdornment data-test-id="MainSendButton" className={this.state.MainTextFildevalue !== "" || this.state.MainAttchmentFiles.length !== 0 ? "ColorChangeGreen" : ""} position="start" onClick={this.CreateConvertionApiCall}>
                    <SendIcon />
                  </InputAdornment>
                </Box>
              ),
            }}
          />
          <input type="file" multiple onChange={this.UploadAttchmentFiles} data-test-id="UploadAttchmentFiles" id="MainUploadFles" style={{ display: "none" }} />
          {
            this.state.MainEmoji &&
            <Box style={{ zIndex: 99999, position: "absolute", bottom: "50px" }} >
              <EmojiPicker
                onEmojiClick={(e) => {
                  this.MainTextFieldEmojiHande(e.emoji)
                }}
                data-test-id="EmojiPickerMain"
                emojiStyle={EmojiStyle.GOOGLE}
                open
              />
            </Box>
          }
        </Box>
      </ApplicationsConversationStyle> }
      
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const ApplicationsConversationStyle = styled(Box)({
  width: "100%",
  backgroundColor: "white",
  "& .inputField": {
    "& .MuiInputBase-root": {
      borderRadius: "8px",
      display: "flex",
      alignItems: "baseline"
    },
    width: "100%",
    borderRadius: "8px",
    boxSizing: "border-box",
    backgroundColor: "white",
    "& .darkPlaceholder::placeholder": {
      color: "rgba(0, 0, 0, 0.87)",
      opacity: 1
    },

    "& .MuiInput-root": {
      "&:focus": {
        outline: "none"
      },
      "&::before": {
        content: "none"
      },
      "&::after": {
        content: "none"
      }
    },
    "&::after": {
      borderBottom: "none"
    },
    "&::before": {
      borderBottom: "none"
    },
    "& .MuiInput-underline:before": { borderBottom: "0px solid red" },
    "& .MuiInput-underline:after": { borderBottom: "0px solid red" },
    "&:hover:not(.Mui-disabled):before": { borderBottom: "0px solid red" },
    "&:hover:not(.Mui-disabled):after": { borderBottom: "0px solid red" },
    "& .MuiSelect-select:focus": { backgroundColor: "transparent" }
  },
  "& .LoaderContaier": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .IconAndNameContainer": {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    position: "relative"
  },
  "& .DeleteAndEditContainer": {
    "& .MuiIconButton-sizeSmall": {
      padding: "2px",
    },
    "& .EditIcon": {
      "& .Icon": {
        borderRadius: '50%',
        padding: '4px',
        color: '#0F172A',
        backgroundColor: '#D1FAE5',
      }
    },
    "& .DeleteIcon": {
      "& .Icon": {
        borderRadius: '50%',
        padding: '4px',
        color: '#DC2626',
        backgroundColor: '#FEE2E2',
      }
    }
  },
  "& .BoldHeaing": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: '14px',
    fontWeight: 700
  },
  "& .greyColor": {
    color: "#64748B"
  },
  "& .LightColorText": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 400,
  },
  "& .SuccessColorText": {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 700,
    color: "#34D399"
  },
  "& .ReplyContainer": {
    marginTop: "10px",
    marginBottom: "10px",
    display: 'flex',
    gap: "5px",
    alignItems: "center",
    cursor: "pointer"
  },
  "& .ShowPreviousCommentsContainer": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .ShowComments": {
    color: "#34D399",
    border: "1px solid #34D399",
    borderRadius: "6px",
    textTransform: "capitalize",
    backgroundColor: "white",
    boxShadow: "none",
    marginTop: "10px",
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none"
    },
  },
  "& .ColorChangeGreen": {
    cursor: "pointer",
    "& .MuiSvgIcon-root": {
      color: '#34D399'
    }
  }
})

const DeleteDialogStyle = styled(Dialog)({
  "& .MuiDialog-paper": {
    width: "400px",
    borderRadius: "10px",
    padding: "20px"
  },
  "& .MuiDialog-paperScrollPaper": {
    maxHeight: "100%",
    overflowY: "auto"
  },
  "& .MuiDialog-paperWidthSm": {
    maxWidth: "none"
  },
  "& .MuiTypography-root": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "16px",
    fontWeight: 700,
    color: "#0F172A"
  },
  "& .MuiBox-root": {
    padding: "0px"
  },
  "& .MuiDialogActions-root": {
    padding: "0px"
  },
  "& .MuiButtonBase-root": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontWeight: 500,
  },
  "& .ButtonContainer": {
    display: "flex",
    marginTop: "20px",
    justifyContent: "center",
    gap: "10px"
  },
  "& .NoButton": {
    backgroundColor: "#DC2626",
    color: "white",
    "&:hover": {
      backgroundColor: "#DC2626",
    }
  },
  "& .YesButton": {
    backgroundColor: "#D1FAE5",
    color: "#34D399",
    "&:hover": {
      backgroundColor: "#D1FAE5",
    }
  }
})

const EditTextBoxModelStyle = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "10px",
    padding: "20px"
  },
  "& .MuiDialog-paperScrollPaper": {
    maxHeight: "100%",
    overflowY: "auto"
  },
  "& .MuiDialog-paperWidthSm": {
    maxWidth: "none"
  },
  "& .MuiTypography-root": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "16px",
    fontWeight: 700,
    color: "#0F172A"
  },
  "& .MuiBox-root": {
    padding: "0px"
  },
  "& .MuiDialogActions-root": {
    padding: "0px"
  },
  "& .MuiButtonBase-root": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontWeight: 500,
  },
  "& .ButtonContainer": {
    display: "flex",
    marginTop: "20px",
    justifyContent: "center",
    gap: "10px"
  },
  "& .EditNoButton": {
    backgroundColor: "#DC2626",
    color: "white",
    "&:hover": {
      backgroundColor: "#DC2626",
    }
  },
  "& .EditYesButton": {
    backgroundColor: "#D1FAE5",
    color: "#34D399",
    "&:hover": {
      backgroundColor: "#D1FAE5",
    }
  }
})

// Customizable Area End