import React from "react";
// Customizable Area Start
import LeadManagementApplicationFormController, {
    Props
} from "./LeadManagementApplicationFormController.web";
import CloseIcon from '@material-ui/icons/Close';
import AppHeader from "../../../../packages/components/src/AppHeader.web";
import { Box, Button, Divider, FormControlLabel, Grid, MenuItem, Modal, Paper, Radio, RadioGroup, RadioProps, Select, TextField, Typography, styled } from "@material-ui/core";
import { CheckCircle, KeyboardArrowDown } from "@material-ui/icons";
import { successTick } from "../../../../packages/blocks/formapprovalworkflow/src/assets";
import SideMenuBar from "../../../../packages/components/src/SideMenuBar.web";
export const configJSON = require("./config");
import { Bounce, ToastContainer } from "react-toastify";
// Customizable Area End

export default class LeadManagementApplicationForm extends LeadManagementApplicationFormController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    showError = (error:boolean,errorTxt:string) => {
      return (
        error && (
          <RequiredTxt data-test-id="errorTestId">
            {errorTxt}
          </RequiredTxt>
        )
      )
    };

    renderBoxStudent = () => {
      return (
        <InputMainBox>
        <InputTxt>
          {configJSON.whereStudentTxt}
        </InputTxt>
        <SelectInput
          displayEmpty
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
          data-test-id="countryChangeTestId"
          value={this.state.countryValue}
          onChange={this.handleCountryChange}
          IconComponent={KeyboardArrowDown}
        >
          {this.state.countryValue == "none" && (
            <MenuItem disabled value="none">
              {configJSON.selectCountryPlaceholderTxt}
            </MenuItem>
          )}
          {this.state.countryData.map((item) => (
            <MenuItem value={item}>{item}</MenuItem>
          ))}
        </SelectInput>
        {this.showError(this.state.countryError,this.state.countryErrorTxt)}
      </InputMainBox>
      )
      }

      renderEmployeeBox = () => {
        return (
          <InputMainBox>
                <InputTxt>
                  {configJSON.employeesTxt}
                </InputTxt>
                <SelectInput
                  displayEmpty
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                  value={this.state.employeeValue}
                  data-test-id="employeeTestId"
                  onChange={this.onEmployeeChange}
                  IconComponent={KeyboardArrowDown}
                >
                  {this.state.employeeValue == "none" && (
                    <MenuItem disabled value="none">
                      Select a Student
                    </MenuItem>
                  )}
                  {this.state.employeesData.map((employee)=>(
                  <MenuItem data-test-id="employeeValueTestId"
                  onClick={()=>this.setStudentId(employee)} className="testMenu"
                   value={`${employee.attributes.first_name} ${employee.attributes.last_name}`}>{`${employee.attributes.first_name} ${employee.attributes.last_name}`}</MenuItem>
                  ))}
                </SelectInput>
        {this.showError(this.state.employeeValueError,this.state.employeeValueErrorTxt)}
              </InputMainBox>
        )
      }
      renderBox = () => {
        return (
          <MainBox>
            <Typography className="Heading">{this.state.showUniversityData ? configJSON.editApplicationTxt 
            :configJSON.addApplicationTxt}</Typography>
            <Box className="innerBox">

              <InputMainBox>
              {this.renderEmployeeBox()}
              </InputMainBox>

              <InputMainBox>
                <CusomiseRadioGroup style={{display:"none"}}
                >
                  <Box className="radioBox">
                    <FormControlLabel value={configJSON.myPreferredTxt} control={<RadioButton />} label={configJSON.myPreferredTxt} />
                    <FormControlLabel value={configJSON.expertTxt} control={<RadioButton />} label={configJSON.expertTxt} />
                  </Box>
                </CusomiseRadioGroup>
          </InputMainBox>
    
    <InputMainBox>
          {this.renderBoxStudent()}
          </InputMainBox>
    
          <InputMainBox>
            <InputTxt>
              {configJSON.preferCollegeTxt}
            </InputTxt>
            <SelectInput
              displayEmpty
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}
              data-test-id="collegeTestId"
              onChange={this.handleCollegeChange}
              value={this.state.preferCollegeValue}
              IconComponent={KeyboardArrowDown}
            >
              {this.state.preferCollegeValue === "none" && (
                <MenuItem disabled value="none">
                  {configJSON.preferCollegeTxt}
                </MenuItem>
              )}
              {this.state.collegeData.map((college)=>(
              <MenuItem data-test-id="courseSetTestId" onClick={()=>this.setCourses(college)} value={college.attributes.university_name}>{college.attributes.university_name}</MenuItem>
              ))}
            </SelectInput>
        {this.showError(this.state.preferCollegeValueError,this.state.preferCollegeValueErrorTxt)}
          </InputMainBox>
    
          <InputMainBox>
            <InputTxt>
              {configJSON.preferCourseTxt}
            </InputTxt>
            <SelectInput
              displayEmpty
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}
              data-test-id="courseTestId"
              value={this.state.preferCourseValue}
              onChange={this.handleCourseChange}
              IconComponent={KeyboardArrowDown}
            >
              {this.state.preferCourseValue == "none" && (
                <MenuItem disabled value="none">
                  {configJSON.preferCoursePlaceHolder}
                </MenuItem>
              )}
              {this.state.coursesData.map((course)=>(
              <MenuItem data-test-id="selectCourseTestId" onClick={()=>this.setIntakeSession(course)} value={course.program_name}>{course.program_name}</MenuItem>
              ))}
            </SelectInput>
        {this.showError(this.state.preferCourseValueError,this.state.preferCourseValueErrorTxt)}
          </InputMainBox>
    
          <Box className="intakeBox" style={{display:"none"}}>
            <InputMainBox >
              <InputTxt>
                {configJSON.intakeTxt}
              </InputTxt>
              <SelectInput
                displayEmpty
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
                value={this.state.inTakeValue}
              onChange={this.onInTakeChange}
              data-test-id="inTakeTestId"
                IconComponent={KeyboardArrowDown}
              >
                {this.state.inTakeValue == "none" && (
                  <MenuItem disabled value="none">
                    {configJSON.selectIntakeTxt}
                  </MenuItem>
                )}
                {this.state.inTakeData.map((session)=>(
                <MenuItem data-test-id="setSessionTestId" onClick={()=>this.setSessionId(session)} value={session.sessionName}>{session.sessionName}</MenuItem>
                ))}
              </SelectInput>
        {this.showError(this.state.inTakeValueError,this.state.inTakeValueErrorTxt)}
            </InputMainBox>
    
            <InputMainBox>
              <InputTxt>
                {configJSON.yearTxt}
              </InputTxt>
              <SelectInput
                displayEmpty
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
                data-test-id="yearTestId"
                onChange={this.handleYearChange}
                value={this.state.intakeYearTxtValue}
                IconComponent={KeyboardArrowDown}
              >
                {this.state.intakeYearTxtValue == "none" && (
                  <MenuItem disabled value="none">
                    {configJSON.selectYear}
                  </MenuItem>
                )}
                {this.state.yearData.map((yearValue)=>(
                <MenuItem value={yearValue}>{yearValue}</MenuItem>
                ))}
              </SelectInput>
        {this.showError(this.state.intakeYearTxtValueError,this.state.intakeYearTxtValueErrorTxt)}
            </InputMainBox>
          </Box>
    
          <InputMainBox style={{display:"none"}}>
            <InputTxt>
              {configJSON.remarkTxt}
            </InputTxt>
            <InputFieldMultiline
                multiline
                onChange={this.onRemarkChange}
                value={this.state.remarkTxtValue}
                placeholder={configJSON.remarkPlaceHolder}
                data-test-id="remarkTestId"
                />
        {this.showError(this.state.remarkTxtValueError,this.state.remarkTxtValueErrorTxt)}
          </InputMainBox>
    
          <InputMainBox className="intakeSelect" style={{display:"none"}}>
            <InputTxt>
              {configJSON.createdByTxt}
            </InputTxt>
            <SelectInput
              displayEmpty
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}
              data-test-id="createdByTestId"
              onChange={this.handleCreatedChange}
              value={this.state.createdValue}
              IconComponent={KeyboardArrowDown}
            >
              {this.state.createdValue == "none" && (
                <MenuItem disabled value="none">
                  {configJSON.createdByTxt}
                </MenuItem>
              )}
              {this.state.createdByData.map((createdBy)=>(
              <MenuItem value={`${createdBy.attributes.email}`}>{`${createdBy.attributes.email}`}</MenuItem>
              ))}
            </SelectInput>
        {this.showError(this.state.createdValueError,this.state.createdValueErrorTxt)}
          </InputMainBox>
          <ButtonBox>
            <CancelButton>{configJSON.cancelTxt}</CancelButton>
            <SaveButton
            data-test-id="submitBtnTestId" onClick={this.onAddApllication}> {this.state.showUniversityData ? configJSON.saveTxt : configJSON.addStudentApplications}</SaveButton>
          </ButtonBox>
        </Box>
      </MainBox>
    );
      };

      editPage = () => {
        return (
          <MainGrid container>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}
          >
            {this.renderBox()}
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="collegeInformationGrid"
          >
            <Paper className="collegeInformation" elevation={0}>
              <Box className="imageBox">
                <img
                  src="https://s3-alpha-sig.figma.com/img/6e5c/bc0a/4bb879f9cb8fa86c4740fed53ab569c4?Expires=1716768000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bcEJphqhrktB0dgqdGQwf4YbeLsTTkUWjITjU4Ji68F4NQSo0drmFaT0tcVD8kccpyJ36TQqzVzQxySzi-CauBrWmE9CeHvXtlQv1J8kRB8BJ93E6UJyxuu3zZDrH3wW92Vb-9Ot-G0X6tT~xnhS8SiU8gYgiE4o1593EDa-IliHmTp0ywIbC9LyYBv7NPxDEwLka3Y4MzPC7yQFGR6vBJ43dFc9wjCb1~wMNq6Ij7gyErIKymiK3w7ohMu4Xyy6g5uBDF08lstyMpIJt1yyrk8H-whaEtau5AH2m2keQW4m19bDVjUniHgzY~BjbIU85CiUwZb-jigdNVg60hGRYA__"
                  alt="clg logo"
                  className="collegeLogo"
                />
              </Box>
              <Box className="headingTxt">
                <Typography>{configJSON.clgName}</Typography>
              </Box>
              <Divider className="sectionDivider" />
              <Box className="headingTxt">
                <Typography>{configJSON.clgWebsiteHeader}</Typography>
              </Box>
              <Box className="subTxt">
                <Typography variant="body2">
                {configJSON.clgWebsiteLink}
                </Typography>
              </Box>
              <Box className="headingTxt">
                <Typography>{configJSON.courseName}</Typography>
              </Box>
              <Box className="subTxt">
                <Typography variant="body2">
                {configJSON.courseLink}
                </Typography>
              </Box>
              <Divider className="sectionDivider" />
              <Box className="courseDescription">
                <Box className="courseDescriptionBox">
                  <Typography variant="body1">
                  {configJSON.tutionFee}
                  </Typography>
                  <Typography variant="body2">
                  {configJSON.tutionFeeData}
                  </Typography>
                </Box>
                <Box className="courseDescriptionBox">
                  <Typography variant="body1">
                  {configJSON.applicationFee}
                  </Typography>
                  <Typography variant="body2">
                  {configJSON.applicationFeeData}
                  </Typography>
                </Box>
                <Box className="courseDescriptionBox">
                  <Typography variant="body1">
                  {configJSON.duration}
                  </Typography>
                  <Typography variant="body2">
                  {configJSON.durationData}
                  </Typography>
                </Box>
                <Box className="courseDescriptionBox">
                  <Typography variant="body1">{configJSON.intake}</Typography>
                  <Typography variant="body2">{configJSON.intakeData}</Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </MainGrid>
        )
      }

      renderSuccessPopUp = () => {
        return(
    <Modal
      onClose={this.handleClose}
            open={this.state.successPopUp}
      style={webStyle.modal}
    >
      <ModalBox>
      <Box style={{...webStyle.flexEnd}}>
        <Button data-test-id="handleCloseId"  onClick={this.handleClose}>
      <CloseIcon />
      </Button>
      </Box>
      <Box style={{...webStyle.gapTwelve,...webStyle.justifyCenter,flexDirection:"column",alignItems:"center"}}>
        <img src={successTick} width={"66px"} height={"61px"}/>
        <Typography variant="h6" component="h2" style={{fontSize:"24px",fontWeight:700}}>
          {configJSON.success}
        </Typography>
                <Typography>{configJSON.successMessage}</Typography>
      </Box>
      <Box style={{...webStyle.justifyCenter,alignItems:"center"}}>
      <NextButton onClick={this.handleClose}>
      <NextButtonTxt>{configJSON.close}</NextButtonTxt>
      </NextButton>
      </Box>
      </ModalBox>
    </Modal>
        )
      }

      showErrorToast = () => {
        return (
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            closeOnClick={true}
            pauseOnHover={true}
            draggable={true}
            theme="colored"
            transition={Bounce}
          />
        )
      }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
          <Box>
            <AppHeader
          isLogin={false}
          onHomeClick={this.onHomeClick}
          onLoginClick={undefined}
          onSignUpClick={undefined}
          data-test-id="appHeaderTestId"
          handleToggle={this.handleToggle}
          isUserLogin={true}
          userName={this.state.userDetails?.attributes?.first_name}
          role={this.state.userDetails?.attributes?.role}
              toggleData={this.state.toggleDrawer}
          profileImg={this.state.userDetails?.attributes?.image}
        />
         <UserProfileBox>
          <SideMenuBar data-test-id="sidebarNavigationBtn"
             navigationToAnyPage={this.navigationToAnyPage} activeComponent="CustomisableUserProfiles"/>
        <Box className="mainBar" style={{paddingLeft:'50px'}}>

        {this.state.showUniversityData ? this.editPage() : this.renderBox()}
        {this.renderSuccessPopUp()}
        {this.showErrorToast()}
      </Box>
        </UserProfileBox>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const UserProfileBox = styled(Box)({
  display: "flex",
  "& .mainBar": {
    width: "100%",
    "@media (max-width: 600px)": {
      marginLeft: "64px",
    },
  }
});

const MainBox = styled(Box)({
    padding: "50px",
    backgroundColor: "#F7F7F7",
    display: "flex",
    flexDirection: "column",
    fontFamily: "Plus Jakarta Sans",
    "& .inputField": {
      backgroundColor: "#fff",
      width: "100%",
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      height: "50px",
      margin: "5px 0px",
      display: "flex",
      justifyContent: "center",
      padding: "0px 10px",
      boxSizing: "border-box",
      "& .MuiInput-root": {
        "&:focus": {
          outline: "none"
        },
        "&::before": {
          content: "none"
        },
        "&::after": {
          content: "none"
        }
      }
    },
    "& .MuiFormControlLabel-label":{
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "24px",
      fontFamily: "Plus Jakarta Sans",
      color: "#334155"
    },
    "& .Heading": {
      fontWeight: 700,
      fontSize: "22px",
      lineHeight: "27.72px",
      fontFamily: "Plus Jakarta Sans",
      color: "#212121"
    },
    "& .radioBox": {
      display: "flex",
      fontFamily: "Plus Jakarta Sans",
    },
    "& .innerBox": {
      margin: "50px",
      display: "flex",
      flexDirection: "column",
      alignSelf: "center",
      "@media screen and (max-width: 600px)": {
        margin: "0px"
      }
    },
    "& .inputText": {
      fontFamily: "Plus Jakarta Sans",
      color: "#334155",
      fontSize: "15px",
      fontWeight: 700
    },
    "& .intakeBox": {
      display: "flex",
      gap: "20px",
      boxSizing: "border-box",
      "@media screen and (max-width: 600px)": {
        flexDirection: "column"
      }
    }
  });
  
const NextButtonTxt = styled(Typography)({textTransform:"none",color:"#ffff",fontWeight:700,fontSize:"16px"});

  const ModalBox = styled(Box)({
    display:"flex",
    flexDirection:"column",
    backgroundColor: "#FFFFFF",
    border: '2px solid #FFFFFF',
    height:"265px",  
    borderRadius:"8px",
    gap:"24px",
    padding:"30px 20px",
    "@media (max-width:960px)": {
      maxWidth:"448px",
        },
        "@media (min-width:720px)": {
          width:"448px",
        },
        })

        const NextButton = styled(Button)({
          width:"224px",
          height:"44px",
          borderRadius:"8px",
          border:"1px solid #34D399",
          padding:"10px 16px",
          gap:"8px",
          background:"#34D399",
          textAlign:"center",
          "&.MuiButton-root:hover": {
            backgroundColor: "#34D399"
          }
        });

  const CusomiseRadioGroup = styled(RadioGroup)({
    gap:"10%"
  })
  
  const CustomizeRadio = styled(Radio)({
    '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
              {
                  color: '#34D399',
                  width:22,
                  height:22
              },
          '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
              color: '#34D399',
              width:22,
              height:22
          },
  })
  
   const RadioButton = (props: RadioProps) => {
    return (
      <CustomizeRadio
        disableRipple
        color="default"
        checkedIcon={<CheckCircle />}
        {...props}
      />
    );
  }
  
  const StarTxt = styled("span")({
    color: "red",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "15px"
  });
  
  const InputMainBox = styled(Box)({
    maxWidth: "500px",
    minWidth: "200px",
    margin:"5px 0"
  });
  
  const MainGrid = styled(Grid)({
    background:"#F7F7F7",
    "& .collegeInformationGrid":{
      padding: "20px 10px",
  display:"flex",
  alignItems:"center",
  
  },
    "& .collegeInformation": {
      padding: "30px 15px",
      boxSizing: "border-box",
      borderRadius: "12px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: "20px",
      width: "100%",
    },
    "& .imageBox": {
      width: "130px",
      height: "130px",
    },
    "& .collegeLogo": {
      borderRadius: "50%",
      width: "100%",
      height: "100%",
    },
    "& .sectionDivider": {
      backgroundColor: "#000000",
      width: "93%",
      margin: "5px 0px",
    },
    "& .headingTxt": {
      "& .MuiTypography-body1": {
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "20px",
        textAlign: "center",
        fontFamily: "Plus Jakarta Sans",
        color: "#334155",
      },
    },
    "& .subTxt": {
      "& .MuiTypography-body2": {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20px",
        textAlign: "center",
        fontFamily: "Plus Jakarta Sans",
        color: "#000000",
      },
    },
    "& .courseDescription": {
      display: "flex",
      flexDirection: "column",
      padding: "10px",
      gap: "20px",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .courseDescriptionBox": {
      display: "flex",
      "& .MuiTypography-body1": {
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "20px",
        textAlign: "center",
        fontFamily: "Plus Jakarta Sans",
        color: "#334155",
      },
      "& .MuiTypography-body2": {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20px",
        textAlign: "center",
        fontFamily: "Plus Jakarta Sans",
        color: "#000000",
      },
    },
    "@media screen and (min-width: 0px) and (max-width: 375px)": {
      padding:"15px",
      "& .MuiTypography-body1": {
          fontSize: "18px !important"
      }
    }
  });

  const InputTxt = styled(Typography)({
    color: "#334155",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight:"24px",
    fontFamily: "Plus Jakarta Sans",
    width: "100%"
  });
  
  const RequiredTxt = styled("span")({
    color: "red",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "15px"
  });
  
  const SelectInput = styled(Select)({
    color: "#64748B",
    backgroundColor: "#fff",
    width: "100%",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    height: "50px",
    margin: "5px 0px",
    display: "flex",
    justifyContent: "center",
    padding: "0px 10px",
    boxSizing: "border-box",
    "& .employeeSelect": {
      color: "red"
    },
    "& .MuiSelect-icon": {
      color: "#0E172A",
      right: "5px"
    },
    "& .MuiInput-root": {
      "&:focus": {
        outline: "none"
      },
      "&::before": {
        content: "none"
      },
      "&::after": {
        content: "none"
      }
    },
    "&.MuiInput-underline:before": {
      borderBottom: "0px solid red"
    },
    "&.MuiInput-underline:after": {
      borderBottom: "0px solid red"
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px solid red"
    },
    "&:hover:not(.Mui-disabled):after": {
      borderBottom: "0px solid red"
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent"
    }
  });
  const ButtonBox = styled(Box)({
    display: "flex",
    justifyContent: "space-around",
    margin: "10px 0px",
    gap:"10px",
    "@media screen and (max-width: 600px)": {
      flexDirection: "column"
    }
  });
  
  const InputFieldMultiline = styled(TextField)({
    width: "100%", border: "1px solid #CBD5E1",
    borderRadius: "8px", minHeight: "145px", margin: "5px 0px",
    padding: "0px 10px", boxSizing: "border-box",
    backgroundColor:"white",
    "& .MuiInput-root": {
      "&:focus": {
        outline: "none"
      },
      "&::before": {
        content: "none"
      },
      "&::after": {
        content: "none"
      }
    }
  });
  
  const SaveButton = styled(Button)({
    minWidth: "205px",
    height:"44px",
    color: "#fff",
    borderRadius: "8px",
    fontWeight: 700,
    margin:"5px 0",
    padding:"10px 16px",
    fontSize: "16px",
    lineHeight:"24px",
    fontFamily: "Plus Jakarta Sans",
    backgroundColor: "#34D399",
    textTransform: "unset",
    "&.MuiButton-root:hover": {
      background: "#34D399",
      color: "#fff"
    }
  });
  
  const CancelButton = styled(Button)({
    maxWidth: "300px",
    minWidth: "150px",
    color: "#059669",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight:"24px",
    margin:"5px 0",
    fontFamily: "Plus Jakarta Sans",
    backgroundColor: "#D6F6EB",
    borderRadius: "8px",
    textTransform: "unset",
    border: "1px solid #34D399",
    "&.MuiButton-root:hover": {
      background: "#D6F6EB",
      color: "#059669"
    }
  });
  const webStyle = {
    justifyCenter:{
      display:"flex",
      justifyContent:"center"
    },
    flexCenter:{
       display:"flex",
       alignItems:"center"
    },
    uploadimageBox: {
      height: "80px",
      width: "80px"
    },
    flexAndGap: {
      display: "flex",
      gap: "10px"
    },
    mainWrapper: {
      display: "flex",
      flexDirection: "column",
      fontFamily: "Roboto-Medium",
      paddingBottom: "30px",
      background: "#fff",
      alignItems: "center"
    },
    containerStyle: {
       padding: "20px",
       background:"#F7F7F7" 
      },
      backgroundColor: {
        background:"#F7F7F7" 
       },
      paddingXTwenty:{
        padding:"0 20px"
      },
    inputStyle: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
      display: "flex",
      width: "100%",
      flexDirection: "column",
      height: "100px",
      justifyContent: "space-between",
    },
    circle: {
      backgroundColor:"white",color:"white",
        width:"24px",height:"24px",borderRadius:"50%",padding:3,border:"1px solid #34D399"
    },
    completed: {
      backgroundColor:"#34D399",color:"white",
        width:"24px",height:"24px",borderRadius:"50%",padding:3
    },
    buttonStyle: {
      marginTop: "40px",
      height: "45px",
      border: "none",
      width: "100%",
      backgroundColor: "rgb(98, 0, 238)",
    },
    gapTwelve:{gap:"12px"},
    defaultText:{
      fontWeight:400,
      color:"#34D399",
    fontSize:"16px"
  },
  displayNone:{
    display:"none"
  },
  themeColorAndCursor:{
  color:"#34D399",cursor:"pointer"
  },
  cursorPointer:{
    cursor:"pointer"
    },
  flexEnd:{
    display:"flex",
    justifyContent:"flex-end"
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
    paper: {
      backgroundColor: "#FFFFFF",
      display: "flex",
      border: '2px solid #FFFFFF',
      height: "265px",
      maxWidth: "448px",
      gap: "24px",
      borderRadius: "8px",
      padding: "30px 20px"
  },
  };
// Customizable Area End