import React from "react";
// Customizable Area Start
import {
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AppHeader from "../../../../packages/components/src/AppHeader.web";
import SideMenuBar from "../../../../packages/components/src/SideMenuBar.web";
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails,Card, Grid, Typography, Button, TextField, Select,RadioProps, Radio, IconButton, Dialog, DialogContent, TableRow,Stepper, Step, StepLabel, StepIconProps, makeStyles, StepConnector, withStyles, Chip, Divider, TableCell, Table, TableHead, TableBody, Paper, Modal, MenuItem } from "@material-ui/core";
export const configJSON = require("./config");
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  defaultImg, DragAndDrop,
   fileImage,
   infoIcon,
   LaunchIcon,
   Phone_Icon,
  SmsIcon,
  UploadIcon,
  uploadNewicon,
} from './assets';
import { Close, KeyboardArrowDown } from "@material-ui/icons";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Loader from "../../../components/src/Loader.web"
import {
  CheckCircle, DeleteForever,
  DeleteOutline, Description,
  Image,
  PictureAsPdf
} from "@material-ui/icons";
import AddIcon from '@mui/icons-material/Add';
import { Bounce, ToastContainer } from "react-toastify";
import {
  FileDownload,
  HourglassEmpty as HourglassEmptyIcon,
  ReportProblem as ReportProblemIcon,
  CheckCircle as CheckCircleIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from "clsx";
import CheckIcon from '@material-ui/icons/Check';
import ViewApplicationController from "./ViewApplicationsController.web";
import * as IMG_CONST from "./assets";
import ApplicationsConversation from "../../conversationthreading/src/ApplicationsConversation.web";
import zIndex from "@material-ui/core/styles/zIndex";
// Customizable Area End

export default class ViewApplication extends ViewApplicationController {
  // Customizable Area Start
  ViewStudentDetailsCard = () => {
    const { StundetData } = this.state
    return (
      <Card className="StudentDetailsContainer" style={{ padding: "0px",boxShadow:'none' }}>
        <Box className="StundetGenralDetails">
          <Grid container style={{ backgroundColor: "#f9fafc", padding: "20px",alignItems:'center' }} >
            <Grid item xs={12} md={1}>
              <Box className="ProfilePicContainer" >
                <img
                  src={`${StundetData.image === "" ? defaultImg : StundetData.image}`}
                  className="StudentProfilePic" />
              </Box>
            </Grid>
            <Grid item xs={12} md={5} style={{marginLeft:'5%'}}>
              <Box>
                <Typography
                  gutterBottom
                  variant="h6"
                  className="ViewStudentHeadig">
                  {StundetData.first_name}
                </Typography>
                <Box className="DetailsCotainer">
                  <Box className="DetailsBox">
                    <Box>
                      <Typography gutterBottom
                        variant="body1"
                        className="GreyColorText">
                        Student ID - {"  "}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        style={{ fontWeight: "700" }}
                        gutterBottom variant="body1">
                        {StundetData.student_id}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="DetailsCotainer">
                  <Box className="DetailsBox" >
                    <Box>
                      <Typography gutterBottom
                        variant="body1" className="GreyColorText">
                        Passport No -{" "}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography style={{ fontWeight: "700" }}
                        gutterBottom variant="body1">
                        {StundetData.passport_number}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className="SecondDetailsContainer">
                <Box>
                  <Box className="IconAndDetailsContainer" >
                    <Box>
                      <img src={Phone_Icon} />
                    </Box>
                    <Box>
                      <Typography
                        className="DarkTextColor"
                        variant="body1">
                        {typeof StundetData.country_code === "string" && "+" + StundetData.country_code?.split("-")[0]}{StundetData.mobile}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="IconAndDetailsContainer" >
                    <Box>
                      <img src={SmsIcon} />
                    </Box>
                    <Box>
                      <Typography
                        className="DarkTextColor"
                        variant="body1">
                        {StundetData.email}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box>
                <Card className="StudentDetailsContainer TabsContainer">
                  <Box className="StundetGenralDetails">
                    <Box className="ButtonAndTabsContainer">
                      <Tabs value={this.state.ActiveTabIndex2}
                        indicatorColor="primary"
                        variant="scrollable"
                        textColor="primary"
                        scrollButtons="auto"
                        onChange={this.HandelTabChange2}
                        className="TabsOfViewProfile"
                        data-test-id="TabsContainer2"
                        aria-label="scrollable auto tabs example" >
                        <Tab label="Student Details" />
                        <Tab label="Assigned Staff" />
                        <Tab label="Course Information" />
                        <Tab label={`Other apps (${this.state.otherApps.total_student_applications})`} />
                      </Tabs>
                    </Box>
                  </Box>
                </Card>
                <Box>
                  {this.RenderActiveTab2(this.state.ActiveTabIndex2)}
                </Box>
              </Box>
        </Box>
      </Card>
    )
  }
  errorCounter = (errors: string | undefined, touched: string | boolean | undefined) => {
    return errors && touched && (
      <RequiredTxt>{errors}</RequiredTxt>
    )
  }
  getStatusIcon = (status: string) => {
    if (status === "pending") {
      return <Tooltip title="pending">
        <HourglassEmptyIcon style={{ color: '#FFA500' }} />
      </Tooltip>
    }
    if (status === "fix_required") {
      return <Tooltip title="Fix required">
        <ReportProblemIcon style={{ color: '#FF0000' }} />
      </Tooltip>
    }
    if (status === "done") {
      return <Tooltip title="Done">
        <CheckCircleIcon style={{ color: '#4CAF50' }} />
      </Tooltip>
    }
  };


  showErrorToast = () => {
    return (
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        theme="colored"
        transition={Bounce}
      />
    )
  }
  renderSuccessModal = () => {
    return (
        <SuccessModal open={this.state.isSuccess}

            onClose={() => {
                this.openCloseSuccssModal()
            }}>
            <Box className="innerSuccessModalBox">
                <CloseIcon className="closeSuccessIcon"
                    onClick={() => this.openCloseSuccssModal()} />
                    
                <Box className="gapSuccessBox">

                    <Box>
                        <img src={IMG_CONST.successImg} />
                    </Box>
                    <Typography className="requestTxt"
                        data-test-id="requestSuccessModal"
                    >
                        {this.state.successMessage.message}
                    </Typography>
                    <Typography className="idTxt">Your Request id is
                    <span className="idSpanTxt">{this.requestIdTxt()}.</span> 
                    </Typography>
                </Box>
                <Box className="btnBox">
                    <Button className="okBtn" data-test-id="successCloseTestID" onClick={() => this.openCloseSuccssModal()}>Ok</Button>
                </Box>
            </Box>
        </SuccessModal>
    )
};
  renderStudentModal = () => {

    const { StundetData } = this.state;
    return (
        <ModalBox open={this.state.isStudentModal}
            data-test-id="modalTestID"
            onClose={() => {
                this.openCloseStudentModal("",null,"","")
            }}>
            <Box className="innerModalBox">
                <CloseIcon data-test-id="CloseIconTestID" className="closeIcon" onClick={() => this.openCloseStudentModal("",null,"","")} />
                <Box className="gapBox">
                    <Box>
                        <Typography className="modalTitle" data-test-id="studentTestId">Select student</Typography>
                        <Typography className="modalDescription">Select student for which you would like to open an account.</Typography>
                        <Typography className="modalDescription"><span style={{ fontWeight: 800 }}>Services provider :</span> {this.state.ModelServicesProvider}</Typography>
                    </Box>

                    <Box className="middleBox">
                        <Box className="modalImgBox">
                            <img height="100%" width="100%" src={this.state.modalImg} />
                        </Box>
                        <Typography className="modalHeadingTxt">{this.state.modalTitle}</Typography>
                    </Box>

                    <Box className="lastBox">
                        <Box className="studentBox">
                            <Typography className="modalStudentTxt">Selected Student</Typography>
                            <Button
                                startIcon={<AddIcon />}
                                className="addStudentBtn"
                                data-test-id="addStudentTestId"
                                onClick={() => this.navigationToAnyPage("OtherServices")}
                            >
                                Add New Student
                            </Button>
                        </Box>
                        <Box style={{marginTop:"16px"}}>
                            <Box style={{display:"flex", gap:"16px"}}>
                            <Typography style={{ fontWeight: "700" }} gutterBottom variant="body1">Student Name:- </Typography>
                            <Typography>{StundetData.first_name} {StundetData.last_name}</Typography>
                            </Box>
                            <Box style={{display:"flex", gap:"16px"}}>
                              <Box style={{display:"flex"}}><Typography style={{ fontWeight: "700" }} gutterBottom variant="body1">Student ID:-  </Typography>
                            <Typography> {StundetData.student_id} </Typography></Box>
                            <Box style={{display:"flex"}}><Typography style={{ fontWeight: "700" }} gutterBottom variant="body1">Passport No:-  </Typography>
                            <Typography> {StundetData.passport_number}</Typography></Box>
                            </Box>
                        </Box>
                        <RequiredTxt>{this.state.studentError}</RequiredTxt>
                    </Box>

                    <Box className="btnBox">
                        <Button className="openAccountBtn"
                            data-test-id="successModalTestId"
                            onClick={() => { this.postOpenAccountApi() }}
                        >
                            Open an account for selected student
                        </Button>
                    </Box>
                </Box>
            </Box>
        </ModalBox>
    )
};


  renderOtherServiceBox = () => {
    return (
        <MainBox>
            <Typography className="headignTxt">{configJSON.otherServiceTxt}</Typography>
            <Grid container spacing={2}>

            {this.state.otherService.map((item,index) => {
                        return (
                            <Grid key={index} item xs={12} sm={6} md={6} lg={6} xl={6} >
              <Box className="cardMainBox CustamClass">
                <Box className="headingBox">
                  <Box className="cardLogoImg">
                    {item.attributes.logo != null &&
                        <img height={"100%"} width={"100%"} className="cardLogoImg" src={item.attributes.logo} />
                    }
                  </Box>
                    <Typography 
                    className="cardHeadingTxt">{item.attributes.title}</Typography>
                </Box>
                {
                    item.attributes.service_provider_name &&
                    <Typography className="descriptionTxt Servicesprovider"><span>Services provider :</span> 
                      <span className="ServicesproviderName">{item.attributes.service_provider_name}</span> 
                  </Typography>
                }
                  <Typography className="descriptionTxt">{item.attributes.description}
                  </Typography>
                <ViewMoreBtn
                  data-test-id={"openCloseModalTestID"+index}
                  className="OpenAccountClass" onClick={() => this.openCloseStudentModal(item.attributes.title, item.attributes.logo , item.id, item.attributes.service_provider_name ?? 'no services provider')}>
                  <Typography className="viewMoreTxt">
                      Open Account
                  </Typography>
                </ViewMoreBtn>
              </Box>
            </Grid>
                        );
                    })}

<Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                        <Box className="cardMainBox CustamClass">
                            <Box className="headingBox">
                                <Box className="cardLogoImg">
                                    <img height={"100%"} width={"100%"} src={IMG_CONST.airlineImg} />
                                </Box>

                                <Typography className="cardHeadingTxt">{configJSON.headingThreeTxt}</Typography>
                            </Box>
                            <Typography className="descriptionTxt">{configJSON.descriptionThreeTxt}
                            </Typography>

                            <ViewMoreBtn className="OpenAccountClass" data-test-id={"OpenAccountClass"}>
                                <Typography className="viewMoreTxt">
                                    {configJSON.bookeAirLineTicket}
                                </Typography>
                            </ViewMoreBtn>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                        <Box className="cardMainBox CustamClass">
                            <Box className="headingBox">
                                <Box className="cardLogoImg">
                                    <img height={"100%"} width={"100%"} src={IMG_CONST.voucherImg} />
                                </Box>

                                <Typography className="cardHeadingTxt">{configJSON.headingFourTxt}</Typography>
                            </Box>
                            <Typography className="descriptionTxt">{configJSON.descriptionFourTxt}
                            </Typography>

                            <ViewMoreBtn className="OpenAccountClass" data-test-id={"voucherTxt"}>
                                <Typography className="viewMoreTxt">
                                    {configJSON.voucherTxt}
                                </Typography>
                            </ViewMoreBtn>
                        </Box>
                    </Grid>
            </Grid>
        </MainBox>
    )
};


  RenderActiveTab = (index: number) => {
  if (index === 0) {
    return this.applicationReq();
  }

  if (index === 1) {
    return this.StudentDoucmentRedner();
  }

  if (index === 2) {
    return(
      <>
      <div style={{display:'none'}}>
      <button data-test-id={'view-app-btn0'} onClick={()=>this.navigationToAnyPageProfile('ViewApplication')}>click to open conv</button>
      <button data-test-id={'view-app-btn1'} onClick={()=>this.navigationToAnyPageProfileNew('ViewApplication')}>conv close</button>
      <button data-test-id={'view-app-btn4'} onClick={()=>this.navigationToAnyPageProfile('ViewApplication')}>conversationMessage</button>
      <button data-test-id={'view-app-btn5'} onClick={()=>this.navigationToAnyPageProfileFync('ViewProfile')}>confirmModalInsideBtn</button>
      </div>
      <ApplicationsConversation indexValue ={index} sendDataCount={this.UpadtedCount} navigation={undefined} />
      </>
    ) 
  }
  
  if (index === 3) {
    return this.TransactionsDataRender();
  }

  if (index === 4) {
    return this.renderOtherServiceBox()

  }
  }

  RenderActiveTab2 = (index: number) => {
    if (index === 0) {
        return this.StudentDoucmentRedner2()
    }
    if (index === 1) {
        return this.AssignedStaffTabRender2()
    }
    if (index === 2) {
      
      return this.PersonalInfoFunction()
    }
    if (index === 3) {
      return this.otherApps()
    }
  }

  DiscountinueTableCell = (ApplicationStatus:any) => {
    const formatText = (text: string) => text.replace(/_/g, ' ');
    return (
      <Box className="DiscountinuedContainer" style={{ display: 'flex', background: '#F0F8FF', padding: '16px' }}>
        <Box>
          <Typography className="ReasonText" style={{ marginRight: '10px', color: '#64748B' }}>
            Reason
          </Typography>
        </Box>
        <Box className="TextAndArrowContainer" style={{ display: 'flex' }}>
          <ArrowForwardIcon />        
          <Typography className="AgentConfirmText" style={{ marginLeft: '10px' }}>
          {formatText(ApplicationStatus)}
          </Typography>
        </Box>
      </Box>
    );
  }

  SuccessStepper = (StepCount: number) => {
    return (
      <Box className="PipelineContainer">
        <Stepper
          alternativeLabel
          activeStep={StepCount}
          className="StperClass"
          connector={<SuccessColorlibConnector />}
        >
          {this.StperData().map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={SuccessColorlibStepIcon}>
                <Typography variant="h6" className="SteperHeading successStp" style={{fontFamily:'Plus Jakarta Sans !important'}}>
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    )
  }


  RefundStepper = (StepCount: number, data: {
    refund_initiated?: boolean;
    refund_received?: boolean;
    refund_rejected?: boolean;
    refund_submitted_to_institution?: boolean;
  }) => {
    
    const { refund_received, refund_rejected } = data;
  
    // Get base stepper data
    let StperData = [...this.SecondStperData()];
  
    // Ensure no duplicates using a Set
    const uniqueSteps = new Set(StperData);
  
    // Add steps conditionally
    if (!refund_received && !refund_rejected) {
      uniqueSteps.add("Refund Received");
    }
    if (refund_received) {
      uniqueSteps.add("Refund Received");
    }
    if (refund_rejected) {
      uniqueSteps.add("Refund Rejected");
    }
  
    // Convert back to an array for rendering
    const finalSteps = Array.from(uniqueSteps);
  
    return (
      <ApplicationsStylednEW>
        <Box className="PipelineContainer">
          <Stepper
            alternativeLabel
            activeStep={StepCount}
            className="StperClass RefundStperClass"
            connector={<RefundlibConnector />}
          >
            {finalSteps.map((label) => (
              <Step key={label} className="refund-stepper-new">
                <StepLabel StepIconComponent={RefundColorlibStepIcon}>
                  <Typography
                    variant="h6"
                    style={{ fontFamily: 'Plus Jakarta Sans !important' }}
                    className="SteperHeading RefundSteperHeading"
                  >
                    {label}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </ApplicationsStylednEW>
    );
  };
  

  enrolledStepper = (
    StepCount: number,
    data: {
      commision_recieved_from_institution?: boolean;
      commision_credited_to_wallet?: boolean;
      // commision_denied_from_university?: boolean;
    },
  ) => {
    
    // const formatText = (text: string) => text.replace(/_/g, ' ');
    // Full list of steps
    const StperData = [
      "Received from Institution",
      "Credited to Wallet",
      // "Denied from University",
    ];
  
    // Highlight steps based on conditions
    // const { commision_recieved_from_institution, commision_credited_to_wallet } = data;
  
    // Set completed steps
    const completedSteps = [
      data?.commision_recieved_from_institution,
      data?.commision_credited_to_wallet,
      // commision_denied_from_university,
    ];
  
    return (
      <ApplicationsStylednEW>
        <Box className="PipelineContainer" style={{display:'flex',alignItems:'center'}}>
          <Typography className="pipeline-text">Commission Current Stage</Typography>
          <Stepper
            alternativeLabel
            activeStep={StepCount}
            className="StperClass RefundStperClass enrolledStperClass"
            connector={<SuccessColorlibConnector />}
          >
            {StperData.map((label, index) => (
              <Step key={label} className="refund-stepper-new">
                <StepLabel StepIconComponent={SuccessColorlibStepIcon}>
                  <Typography
                    variant="h6"
                    style={{
                      fontFamily: "Plus Jakarta Sans !important",
                      color: completedSteps[index] ? "green" : "gray",
                    }}
                    className="SteperHeading RefundSteperHeading"
                  >
                    {label}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </ApplicationsStylednEW>
    );
  };

  enrolledStepperFinal = (
    StepCount: number,
    data: {
      // commision_recieved_from_institution?: boolean;
      // commision_credited_to_wallet?: boolean;
      commision_denied_from_university?: boolean;
    },
    applicationReq: string,
  ) => {
    const formatText = (text: string) => text.replace(/_/g, ' ');
    // Full list of steps
    const StperData = [
      // "Received from Institution",
      // "Credited to Wallet",
      "Denied from University",
    ];
  
    // Highlight steps based on conditions
    const { commision_denied_from_university } = data;
  
    // Set completed steps
    const completedSteps = [
      // commision_recieved_from_institution,
      // commision_credited_to_wallet,
      commision_denied_from_university,
    ];
  
    return (
      <ApplicationsStylednEW>
        <Box className="PipelineContainer" style={{display:'flex',alignItems:'center'}}>
        <Typography className="pipeline-text">Commission Current Stage</Typography>
          <Stepper
            activeStep={StepCount}
            alternativeLabel
            connector={<SuccessColorlibConnector />}
            className="StperClass RefundStperClass enrolledStperClass"
          >
            {StperData.map((label, index) => (
              <Step key={label} className="refund-stepper-new">
                <StepLabel StepIconComponent={SuccessColorlibStepIcon}>
                  <Typography
                   className="SteperHeading RefundSteperHeading"
                    style={{
                      fontFamily: "Plus Jakarta Sans !important",
                      color: completedSteps[index] ? "green" : "gray",
                    }}
                    variant="h6"
                  >
                    
                    {label}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box className="DiscountinuedContainer" style={{marginTop:'10px', background: '#F0F8FF', display: 'flex', padding: '16px' }}>
        <Box>
          <Typography className="ReasonText" style={{ color: '#64748B', marginRight: '10px' }}>
            Reason
          </Typography>
        </Box>
        <Box className="TextAndArrowContainer" style={{ display: 'flex' }}>
          <ArrowForwardIcon />
          <Typography className="AgentConfirmText" style={{ marginLeft: '10px' }}>
            {formatText(applicationReq)}
          </Typography>
        </Box>
      </Box>
      </ApplicationsStylednEW>
    );
  };
  TransactionsDataRender = () => {
    return (
      <Box className="TabDetailsContainer">
        <Typography component="div" dangerouslySetInnerHTML={{ __html: this.state.eligibilty?.eligibility }} />
      </Box>
    )
  }

  getIconForFileType = (fileName: string) => {
    if (fileName?.includes('pdf')) {
      return <PictureAsPdf style={{ color: '#000000' }} />
    };
    if (fileName?.includes('png') || fileName?.includes('jpg') || fileName?.includes('svg')) {
      return <Image style={{ color: '#000000' }} />
    };
    if (fileName?.includes('doc') || fileName?.includes('docx')) {
      return <Description style={{ color: '#000000' }} />;
    }

  };

StudentDoucmentRedner=()=>{
    const { allDocuments } = this.state;

  return (
    <Box style={{background:'#fff'}}>
      {allDocuments?.data?.map((doc:any) => (
        <Box 
          key={doc.id} 
          display="flex" 
          alignItems="center" 
          justifyContent="space-between" 
          borderBottom="1px solid #e0e0e0" 
          padding="16px"
        >
          <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',borderRadius:'8px',width:'100%',padding:'0 15px',border:'1px solid #E2E8F0'}}>
          <div style={{background:'#fff',padding:'20px'}}>
            <div style={{display:'flex',flexDirection:'column'}}>
              <div style={{display:'flex'}}>
              <img src={fileImage} style={{marginRight:'10px'}} />
              <Typography style={{fontWeight:'bold',paddingLeft:'6px ,18px',whiteSpace:'nowrap',color:'#2A0066'}}>
                {doc.attributes.document_name}
              </Typography>
              </div>
              <Box display="flex" flexDirection="column">
            <Typography variant="body2" color="textSecondary" style={{paddingLeft:'44px'}}>
              {doc.attributes.file_size}
            </Typography>
          </Box>
            </div>
            </div>
          <Button
            variant="outlined"
            style={{color:'#64748B',borderRadius:'8px',fontWeight:'bold',textTransform:'capitalize',fontSize:'16px',border:'2px solid #64748B',padding:'2px 15px'}}
            endIcon={<DownloadIcon />}
            href={doc.attributes.institute_documents[0]}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download
          </Button>
          </div>
        </Box>
      ))}
    </Box>
  );
}

applicationReq = () => {
  const { applicationReq } = this.state;
  const applicationData = applicationReq?.data || {};
  const counts = applicationReq?.data?.application_received?.counts || {};
  const lastRequirementDate = 'Sep. 20, 2023';

  type StatusType = 'missing' | 'under_review' | 'fix_required' | 'approved';

  // Function to render count with an icon
  const renderCountWithIcon = (count: string, status: StatusType) => {
    const iconConfig = {
      missing: { icon: infoIcon, color: 'orange' },
      under_review: { icon: HourglassEmptyIcon, color: 'gray' },
      fix_required: { icon: CloseIcon, color: 'red' },
      approved: { icon: CheckCircleIcon, color: 'green' }
    };

    const { icon: IconComponent, color } = iconConfig[status];
    return (
      <Tooltip title={status}>
         <div style={{ display: 'flex', alignItems: 'end', marginRight: '25px' }}>
        <Typography variant="body1" style={{ marginRight: '4px' }}>{count}</Typography>
        {typeof IconComponent === 'string' ? (
          <img src={IconComponent} alt={`${status} icon`} style={{ width: '30px', height: '30px' }} />
        ) : (
          <IconComponent style={{ color: color }} />
        )}
      </div>
      </Tooltip>
    );
  };

  const getStatusStyles = (status: string) => {
    switch (status) {
      case 'missing':
        return { backgroundColor: '#FEF3C7', color: '#92400E' }; 
      case 'fix_required':
        return { backgroundColor: '#FEE2E2', color: '#B91C1C' };
      case 'under_review':
        return { backgroundColor: '#F1F5F9', color: '#475569' }; 
      case 'approved':
        return { backgroundColor: '#D1FAE5', color: '#065F46' }; 
      default:
        return { backgroundColor: 'transparent', color: 'inherit' };
    }
  };

  return (
    <AccordionWrapper>
      {Object.entries(applicationData).map(([stageKey, stageValue]) => {
        const { requirements = [], counts = {} } = stageValue as any;
        const stageTitle = stageKey
          .split('_')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');

        return (
          <ExpansionPanel key={stageKey} style={{marginBottom:'0'}}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIconNew />}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6">{stageTitle}</Typography>
                <Typography variant="body2">
                  Last requirement completed on {lastRequirementDate}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {renderCountWithIcon(counts.missing, 'missing')}
                {renderCountWithIcon(counts.under_review, 'under_review')}
                {renderCountWithIcon(counts.fix_required, 'fix_required')}
                {renderCountWithIcon(counts.approved, 'approved')}
              </div>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails className="expandable-bar">
              <div style={{ width: '100%' }}>
                {requirements.map((requirement:any) => {
                  const isExpanded = this.state.expandedPanel === requirement.id;
                  const status = requirement.status || 'default'; 
                  const statusStyles = getStatusStyles(status);
                  return (
                    <ExpansionPanel key={requirement.id}>
                      <ExpansionPanelSummary className="internal-summary">
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          <img src={infoIcon} style={{ marginRight: '10px' }} />
                          <Typography variant="h6">{requirement.task_name}</Typography>
                        </div>
                        <div style={{ display: 'flex' }}>
                       <span
            className="statusInactive"
            style={{
              ...statusStyles,
              marginRight: '10px',
              padding: '5px',
              borderRadius: '4px',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              fontSize: '12px',
            }}
          >
            {status}
          </span>
              <img
                src={UploadIcon}
                data-test-id="UploadDoumentModelOpenEducationDetails"
                onClick={() => {
                  this.UploadDoumentModelOpen(
                    "Upload document",
                    requirement.field_label,
                    requirement.field_placeholder,
                    requirement.id
                  );
                }}
              />
                </div>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails>
                <div style={{ width: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      marginBottom: '16px',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        flexGrow: 1,
                        marginLeft: '10px',
                        color: '#3A5A7A',
                        fontSize: '14px',
                        fontFamily: "Plus Jakarta Sans",
                      }}
                    >
                      {requirement.task_description}
                    </Typography>
                    <Button
                      onClick={() => this.togglePanel(requirement.id)}
                      variant="text"
                      size="small"
                      style={{ color: '#64748B', fontWeight: '700' }}
                    >
                      {isExpanded ? 'View Less' : 'View More'}
                    </Button>
                  </div>

                  {isExpanded && (
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: '10px' }}>
                        {requirement?.external_link && (
                          <Box className="DownloadButtonContainer download-box">
                            <div
                              style={{ display: 'flex' }}
                              data-test-id="ViewDocEdution"
                              onClick={() => {
                                this.OpenDocument(requirement.external_link);
                              }}
                            >
                              <span style={{ marginRight: '5px' }}>
                                <img src={LaunchIcon} />
                              </span>
                              {requirement.external_link}
                            </div>
                          </Box>
                        )}
                      </div>

                      {requirement.sample_file_url && (
                        <Box style={{ marginBottom: '10px' }} className="DownloadButtonContainer download-box">
                          <div
                            style={{ display: 'flex' }}
                            data-test-id="ViewDocEdution"
                            onClick={() => {
                              this.OpenDocument(requirement.sample_file_url);
                            }}
                          >
                            <span
                              style={{
                                marginRight: '5px',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                display:'flex',
                                alignItems:'center',
                              }}
                            >
                              <PictureAsPdf style={{ color: '#000000', marginRight: '10px' }} />
                              {requirement.sample_file_url}
                            </span>
                          </div>
                        </Box>
                      )}
                    </div>
                  )}
                  <div style={{ display: 'flex' }}>
                    {requirement.file && (
                      <Tooltip title={`Uploaded on ${requirement.uploaded_on}`}>
                      <div className="download-box download-new">
                        <span style={{ marginRight: '0' }}>
                          {this.getIconForFileType(requirement.file.name)}
                        </span>
                        <span className="downtext">
                          {requirement.file.name}
                          <p style={{ color: '#64748B', fontSize: '12px' }}>
                            {requirement.file.size}
                          </p>
                        </span>
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{ border: 'none' }}
                          onClick={() => {
                            this.OpenDocument(requirement.file.url);
                          }}
                        >
                          <img src={uploadNewicon} />
                        </Button>
                        {requirement.comment && (
                          <Button
                            variant="outlined"
                            color="primary"
                            style={{ border: 'none' }}
                            onClick={() => this.DeleteDoument(requirement.id)}
                          >
                            <IconButton className="DeleteIconButtonClass">
                              <DeleteOutline />
                            </IconButton>
                          </Button>
                        )}
                      </div>
                      </Tooltip>
                    )}
                    {requirement.comment && (
                      <FileUploadErrorText dangerouslySetInnerHTML={{ __html: requirement.comment }} />
                    )}
                  </div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                  );
                })}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </AccordionWrapper>
  );
};
  StudentDoucmentRedner2=()=>{
    const { StundetData } = this.state;
    return(
        <Box className="PersonalInfoContainer" >
    <Grid container className="PersonalInfoGridContainer">
      <Grid item md={12} xs={12} className="Section">
        <Grid container className="DetailsContainer">
          <div style={{display:'flex',width:'100%'}}>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              className="Title LightcolorText">
              Students Address
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              className="Title BoldTitle">
              {StundetData.city}
            </Typography>
          </Grid>
          </div>
          <Grid item xs={12} md={6} style={{ border: "none" }}>
            <Typography
              variant="body1"
              className="Title LightcolorText">
              Emergency Contact Details
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} style={{ border: "none" }}>
                    <Box className="Title" >
                      <Box className="NameAndDetails">
                        <Box><Typography variant="body1" className="smallTitle" >Name:</Typography></Box>
                        <Box>
                          <Typography variant="body1" className="BoldTitle">
                            {StundetData.emergency_contact_person}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="NameAndDetails">
                        <Box>
                          <Typography
                            variant="body1"
                            className="smallTitle">
                            Mobile:
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="body1"
                            className="smallTitle"
                            style={{fontWeight:'bold'}}
                            >
                            {StundetData.emergency_contact_mobile}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="NameAndDetails">
                        <Box>
                          <Typography
                            variant="body1"
                            className="smallTitle">
                            Email:
                          </Typography>
                        </Box>
                        <Box style={{ width: "200px" }}>
                          <Typography
                            noWrap
                            variant="body1"
                            style={{fontWeight:'bold'}}
                            className="smallTitle">
                            {StundetData.emergency_contact_email}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <div style={{borderTop:'1px solid #E2E8F0',display:'flex',width:'100%'}}>
                  <Grid item xs={6}>
            <Typography
              variant="body1"
              className="Title LightcolorText">
              Nationality
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              className="Title BoldTitle">
              {this.state.countryData.filter((item) => {
                return item.isoCode === StundetData.country_of_nationality
              })[0]?.name ?? "N/A"}
            </Typography>
          </Grid>
                  </div> 
          <Grid item xs={6}>
            <Typography
              variant="body1"
              className="Title LightcolorText">
              Residence
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              className="Title BoldTitle">
              {this.state.countryData.filter((item) => {
                return item.isoCode === StundetData.country_of_residence
              })[0]?.name ?? "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Box>
    )
  }
  coversationRender = () => {
    const { ApplicationDataList } = this.state || {};
    if (!ApplicationDataList) {
      return <div>No application data available</div>;
    }
  
    let ChipColorClass = '';
    let ChipText = '';
    if (ApplicationDataList.ApplicationStatus === 'in_progress') {
      ChipColorClass = 'InprogressClass';
      ChipText = 'IN PROGRESS';
    } else if (ApplicationDataList.ApplicationStatus === 'refund') {
      ChipColorClass = 'RefundClass';
      ChipText = 'REFUND';
    } else if (ApplicationDataList.ApplicationStatus === 'enrolled') {
      ChipColorClass = 'EnrolledClass';
      ChipText = 'ENROLLED';
    } else if (ApplicationDataList.ApplicationStatus === 'discontinued') {
      ChipColorClass = 'DiscountinuedClass';
      ChipText = 'DISCONTINUED';
    }
  
    let StperCount;
    let SterperJsk = null;
    if (ApplicationDataList.ApplicationStatus === 'refund') {
      const StperCount = this.RefundStperCount(ApplicationDataList.ApplicationStageForRefund);
      SterperJsk = this.RefundStepper(StperCount, ApplicationDataList.ApplicationStageForRefund);
    } else if (ApplicationDataList.ApplicationStatus === 'discontinued') {
      SterperJsk = this.DiscountinueTableCell(ApplicationDataList.ApplicationStageForDiscontinue);
    }  else if (ApplicationDataList.ApplicationStatus === 'in_progress'){
      StperCount= this.SuccessStperCount(ApplicationDataList.ApplicationStageForInprogress);
      // SterperJsk = this.SuccessStepper(StperCount)
    }    
    else if (ApplicationDataList.ApplicationStatus === 'enrolled'){
      if (ApplicationDataList.CurrentStage === 'commision_denied_from_university' ){
        StperCount= this.SuccessStperCountEnrolledFinal(ApplicationDataList.ApplicationStageForEnrolledFinal);
        SterperJsk = this.enrolledStepperFinal(StperCount,ApplicationDataList.ApplicationStageForEnrolledFinal,ApplicationDataList.ApplicationStageForDiscontinue)
      }   else{
        StperCount= this.SuccessStperCountEnrolled(ApplicationDataList.ApplicationStageForEnrolled);
        SterperJsk = this.enrolledStepper(StperCount,ApplicationDataList.ApplicationStageForEnrolled)
      } 
    }    
          
  
    const PastHistoryData = ApplicationDataList?.pastHistory || null;
    let PastHistoryStepperJsk = null;
    
    // Check if PastHistoryData is empty
    if ((!PastHistoryData || Object.keys(PastHistoryData).length === 0) && ApplicationDataList.ApplicationStatus === 'in_progress') {
      const PastHistoryStepCount = this.SuccessStperCount(ApplicationDataList.ApplicationStageForInprogress);
      PastHistoryStepperJsk = this.SuccessStepper(PastHistoryStepCount);
    } else if ((!PastHistoryData || Object.keys(PastHistoryData).length === 0) && ApplicationDataList.ApplicationStatus === 'enrolled') {
      PastHistoryStepperJsk = this.SuccessStepper(11);
    } else if (
      PastHistoryData?.refund_initiated !== undefined &&
      (PastHistoryData.refund_initiated || PastHistoryData.refund_submitted_to_institution || 
        PastHistoryData.refund_received || PastHistoryData.refund_rejected)
    ) {
      const RefundStepCount = this.RefundStperCount(PastHistoryData);
      PastHistoryStepperJsk = this.RefundStepper(RefundStepCount, PastHistoryData);
    } else if (PastHistoryData && Object.keys(PastHistoryData).length > 0) {
      const PastHistoryStepCount = this.SuccessStperCount(PastHistoryData);
      PastHistoryStepperJsk = this.SuccessStepper(PastHistoryStepCount);
    }
    
    return (
      <div>
        <AppDetailsBox>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Box className="BoxContainer" style={{whiteSpace:'nowrap'}}>
              <Button
              data-test-id={"view-app-btn3"}
                onClick={() => {
                  this.navigationToAnyPage('ViewProfile');
                }}
                className="StudentIdText"
                startIcon={<ArrowBackIosIcon />}
                style={{fontSize:'14px'}}
              >
                App ID &nbsp;-<span style={{fontSize:'14px'}} className="BoldText">&nbsp;{ApplicationDataList.AppId}</span>
              </Button>
            </Box>
            <StatusChip label={ChipText} className={ChipColorClass} />
          </Box>
          <div className="recent-stepper">
          {SterperJsk}
          </div>
          <Typography variant="h6" style={{ fontWeight: 'bold' }} gutterBottom>
            {ApplicationDataList.course}
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {ApplicationDataList.UniversityName}
          </Typography>
          <Grid container spacing={2} style={{ marginTop: '20px' }} className="grid-box-new">
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between',borderBottom:'1px solid #E2E8F0' }}>
              <Typography variant="body2" color="textSecondary">
                University ID
              </Typography>
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                {ApplicationDataList.UniversityId}
              </Typography>
            </Grid>
            <Divider />
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' , borderBottom:'1px solid #E2E8F0' }}>
              <Typography variant="body2" color="textSecondary">
                Intake
              </Typography>
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                -
              </Typography>
            </Grid>
            <Divider />
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between',borderBottom:'1px solid #E2E8F0' }}>
              <Typography variant="body2" color="textSecondary">
                Agent's branch name
              </Typography>
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                {ApplicationDataList.AgentBranchName}
              </Typography>
            </Grid>
            <Divider />
          </Grid>
        </AppDetailsBox>
        <div className="application-card">
          <div className="application-stepper"></div>
          <div className="past-history">
          {PastHistoryStepperJsk}
          </div>
          <AppDetailsBox>
            <Divider />
            <Grid container spacing={2} style={{ marginTop: '20px' }} className="grid-box-new">
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between',borderBottom:'1px solid #E2E8F0' }}>
                <Typography variant="body2" color="textSecondary">
                  Created on
                </Typography>
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  {ApplicationDataList?.StartDate}
                </Typography>
              </Grid>
              <Divider />
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between',borderBottom:'1px solid #E2E8F0' }}>
                <Typography variant="body2" color="textSecondary">
                  Created by
                </Typography>
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  {ApplicationDataList?.AppliedBy}
                </Typography>
              </Grid>
              <Divider />
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between',borderBottom:'1px solid #E2E8F0' }}>
                <Typography variant="body2" color="textSecondary">
                  Last activity date
                </Typography>
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  {ApplicationDataList?.UpdatedAt}
                </Typography>
              </Grid>
              <Divider />
            </Grid>
          </AppDetailsBox>
        </div>
      </div>
    );
  };
  PersonalInfoFunction=()=>{
    const { CourseInfo } = this.state;
    return(
        <Box className="PersonalInfoContainer" >
    <Grid container className="PersonalInfoGridContainer">
      <Grid item md={12} xs={12} className="Section">
        <Grid container className="DetailsContainer">
          <div style={{display:'flex',width:'100%',borderBottom:'1px solid #E2E8F0'}}>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              className="Title LightcolorText">
              University ID
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              className="Title BoldTitle">
              {CourseInfo[0]?.university_id}
            </Typography>
          </Grid>
          </div>
          <Grid item xs={12} md={6} style={{ }}>
            <Typography
              variant="body1"
              className="Title LightcolorText">
              Level of course
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} style={{ }}>
                    <Box className="Title" >
                      <Box className="NameAndDetails">
                        <Box>
                          <Typography variant="body1" className="BoldTitle">
                          {CourseInfo[0]?.program_level}
                          </Typography>
                        </Box>
                      </Box>
                      
                      </Box>
                  </Grid>

                  <Grid item xs={12} md={6} style={{ }}>
            <Typography
              variant="body1"
              className="Title LightcolorText">
                Course duration            
                </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
                    <Box className="Title" >
                      <Box className="NameAndDetails">
                        <Box>
                          <Typography variant="body1" className="BoldTitle">
                          -
                          </Typography>
                        </Box>
                      </Box>
                      
                      </Box>
                  </Grid>
                  <Grid item xs={6}>
            <Typography
              variant="body1"
              className="Title LightcolorText">
              Fees
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              className="Title BoldTitle">
             {CourseInfo[0]?.fees}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              className="Title LightcolorText">
              Course Start Date
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              className="Title BoldTitle">
             -
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Box>
    )
  }
  otherApps = () => {
    const viewAppData = localStorage.getItem("ViewAppData");
    const parsedViewAppData = viewAppData ? JSON.parse(viewAppData) : null;
  
    const filteredApps = this.state.otherApps?.data?.filter((row: any) => {
      const { application_id } = row.attributes;
      return parsedViewAppData?.AppId !== application_id;
    });
  
    return (
      <TablePaper className="table-appId">
        <Table className="table" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>App ID</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Current Stage</TableCell>
              <TableCell>Created By</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredApps?.length > 0 ? (
              filteredApps.map((row: any) => {
                const { application_id, application_status, application_stage, applied_by } = row.attributes;
  
                // Determine the class name based on the status
                let ChipClassName = '';
                let ChipText = '';
                if (application_status === 'in_progress') {
                  ChipClassName = 'InprogressClass';
                  ChipText = 'IN PROGRESS';
                } else if (application_status === 'refund') {
                  ChipClassName = 'RefundClass';
                  ChipText = 'REFUND';
                } else if (application_status === 'enrolled') {
                  ChipClassName = 'EnrolledClass';
                  ChipText = 'ENROLLED';
                } else if (application_status === 'discontinued') {
                  ChipClassName = 'DiscountinuedClass';
                  ChipText = 'DISCONTINUED';
                }
  
                const currentStage =
                  Object.keys(application_stage).find(
                    (key) => application_stage[key] === true
                  ) || "No stage";
  
                return (
                  <TableRow key={row.id}>
                    <TableCell>{application_id}</TableCell>
                    <TableCell>
                      <StatusChip
                        label={ChipText}
                        className={ChipClassName}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>{currentStage.replace(/_/g, " ")}</TableCell>
                    <TableCell>{applied_by}</TableCell>
                    <TableCell>
                      <Button
                        className="viewAppBtn"
                        data-test-id="navigationBtnNew"
                        onClick={() => {
                          this.NaviGateToViewApplication(row.id, application_id);
                        }}
                      >
                        <ArrowForwardIcon />
                        View App
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography align="center">No Data</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TablePaper>
    );
  };
  
  ReAssigneModel = () => {
    const { ReAssigneModelState, associateValue, StundetData } = this.state
    return (
      <Modal onClose={this.OnCloseReAssigneModelState} open={ReAssigneModelState}
        data-test-id="filterModal">
        <ModalContent>
          <BoxPopupClose>
            <Close onClick={this.OnCloseReAssigneModelState} data-test-id="OnCloseReAssigneModelState" />
          </BoxPopupClose>
          <ModaInnerBox >
            <Box style={{ width: "100%" }}>
              <BoxPopUpTitle className="filterTitle">
                <FilterModalTitle style={{ fontWeight: 700 }}>Reassign application</FilterModalTitle>
              </BoxPopUpTitle>
            </Box>
            <Box className="insideBox">
              <Box className="innerContent">
                <Box style={{ marginTop: "16px" }}>
                  <Box style={{ display: "flex", gap: "16px" }}>
                    <Typography style={{ fontWeight: "700" }} gutterBottom variant="body1">Student Name:- </Typography>
                    <Typography>{StundetData.first_name + " " + StundetData.last_name}</Typography>
                  </Box>
                  <Box style={{ display: "flex", gap: "16px" }}>
                    <Box style={{ display: "flex" }}><Typography style={{ fontWeight: "700" }} gutterBottom variant="body1">Student ID:-  </Typography>
                      <Typography> {StundetData.student_id} </Typography></Box>
                    <Box style={{ display: "flex" }}><Typography style={{ fontWeight: "700" }} gutterBottom variant="body1">Passport No:-  </Typography>
                      <Typography> {StundetData.passport_number}</Typography></Box>
                  </Box>
                </Box>
              </Box>

              <Box className="innerContent">
                <Typography className="inputTxt">
                  Assign To
                </Typography>
                <Select
                  className="selectInput"
                  displayEmpty
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null,
                  }}
                  style={{
                    color: associateValue == "none" ? "#999" : "#000"
                  }}
                  value={associateValue}
                  data-test-id="selectBranch"
                  IconComponent={KeyboardArrowDown}
                  onChange={this.handleBranchChange}
                >
                  <MenuItem value="none">
                    Select Agent/Counsellor
                  </MenuItem>
                  {this.state.ReAssigneModelData?.agents?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      <MenuListItems>
                        <Box className="cardLogoImg">
                          <img
                            width="100%"
                            height="100%"
                            src={item.profile_image?.url}
                            alt={item.agent_name}
                            className="cardLogoImg"
                          />
                        </Box>
                        <Box>
                          <Box className="listContentBox">
                            <Typography className="listContentTitle">ID:</Typography>
                            <Typography className="listContentValue">{item.agent_id}</Typography>
                          </Box>
                          <Box className="listContentBox">
                            <Typography className="listContentTitle">Name:</Typography>
                            <Typography className="listContentValue">{item.agent_name}</Typography>
                          </Box>
                          <Box className="listContentBox">
                            <Typography className="listContentTitle">Designation:</Typography>
                            <Typography className="listContentValue">{item.designation}</Typography>
                          </Box>
                          <Box className="listContentBox">
                            <Typography className="listContentTitle">Email:</Typography>
                            <Typography className="listContentValue">{item.email}</Typography>
                          </Box>
                        </Box>
                      </MenuListItems>
                    </MenuItem>
                  ))}
                </Select>
                {this.state.reassignAgentError && (
                  <RequiredTxt>{this.state.reassignAgentError}</RequiredTxt>
                )}
              </Box>
            </Box>
            <StyledBoxButtonWrapper>
              <StyledApplyFilterButton
                onClick={this.reassignStudentApiCallFuncation}
                data-test-id="applyReassgine"><StyledTypoGraphyApply>Reassign student to chosen member</StyledTypoGraphyApply></StyledApplyFilterButton>
            </StyledBoxButtonWrapper>
          </ModaInnerBox>
        </ModalContent>
      </Modal>
    )
  }
  
  
  AssignedStaffTabRender2 = () => {
    const { VisionStaffData } = this.state;  
    const agentStaffData = VisionStaffData[0]?.attributes?.agent_staff_info;
    const salesManagerData = VisionStaffData[0]?.attributes?.sales_manager_info;
    const universityStaffData = VisionStaffData[0]?.attributes?.university_staff_info;
      return (
<Box className="PersonalInfoContainer" >
    <Grid container className="PersonalInfoGridContainer">
      <Grid item md={12} xs={12} className="Section">
        <Grid container className="DetailsContainer">
          <div style={{display:'flex',width:'100%'}}>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              className="Title LightcolorText">
              Counsellor
            </Typography>
            <span style={{padding:0,cursor:'pointer'}} onClick={() => {
                  this.OpenReAssigneModel(agentStaffData?.attributes?.agent?.agent_id)
                }} data-test-id={"ReassignBtn"} className="ReassignapplicationBtn">
                  Reassign Student
                </span>
          </Grid>
          <Grid item xs={6}>
          {agentStaffData && agentStaffData.map((staffItem:any) => (
  <Box key={staffItem.id} className="VisitonStaffContainer">
          <Card className="CardOfAssignedStaff" style={{width:'100%',boxShadow:'none',marginBottom:'20px'}}>
            <Box className="StaffLogoContainer">
              <img
                className="StaffLogo"
                src={staffItem?.attributes?.agent?.image || defaultImg}
                alt="Staff"
              />
            </Box>
            <div style={{width:'100%'}}>
            <Box className="FirstNameLastNameContainer" style={{display:'flex',justifyContent:'space-between'}}>
            <Typography align="center" gutterBottom variant="h6" className="AssignedStaffHeading">
               Name
              </Typography>
              <Typography align="center" gutterBottom variant="h6" className="AssignedStaffHeading-content">
                {staffItem?.attributes?.agent?.first_name} {staffItem?.attributes?.agent?.last_name}
              </Typography>
            </Box>
            <Box className="TiltleContainer"style={{display:'flex',justifyContent:'space-between'}}>
            <Typography align="center" gutterBottom variant="h6" className="AssignedStaffHeading">
            Designation
              </Typography>
              <Typography align="center" gutterBottom variant="h6" className="AssignedStaffHeading-content">
              {staffItem?.attributes?.agent?.role}
              </Typography>
            </Box>
            <Box className="PhoneContainer" style={{display:'flex',justifyContent:'space-between'}}>
            <Typography align="center" gutterBottom variant="h6" className="AssignedStaffHeading">
            Mobile
              </Typography>
              <Typography align="center" gutterBottom variant="h6" className="AssignedStaffHeading-content">
                  +{staffItem?.attributes?.agent?.phone_number}
              </Typography>
            </Box>
            </div>
          </Card>
  </Box>
))}
          </Grid>
          </div>
          <div style={{display:'flex',width:'100%'}}>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              className="Title LightcolorText">
              Sales manager
            </Typography>
          </Grid>
          <Grid item xs={6}>
  <Box key={salesManagerData} className="VisitonStaffContainer">
    {/* <Box> */}
    <Card className="CardOfAssignedStaff" style={{width:'100%',boxShadow:'none',marginBottom:'20px',marginTop:'20px'}}>
            <Box className="StaffLogoContainer">
              <img
                className="StaffLogo"
                src={salesManagerData?.attributes?.image || defaultImg}
                alt="Staff"
              />
            </Box>
            <div style={{width:'100%'}}>
            <Box className="FirstNameLastNameContainer" style={{display:'flex',justifyContent:'space-between'}}>
            <Typography align="center" gutterBottom variant="h6" className="AssignedStaffHeading">
               Name
              </Typography>
              <Typography align="center" gutterBottom variant="h6" className="AssignedStaffHeading-content">
                {salesManagerData?.attributes?.first_name} {salesManagerData?.attributes?.last_name}
              </Typography>
            </Box>
            <Box className="FirstNameLastNameContainer" style={{display:'flex',justifyContent:'space-between'}}>
            <Typography align="center" gutterBottom variant="h6" className="AssignedStaffHeading">
            Designation
              </Typography>
              <Typography align="center" gutterBottom variant="h6" className="AssignedStaffHeading-content">
              {salesManagerData?.attributes?.role}
              </Typography>
            </Box>
            <Box className="FirstNameLastNameContainer" style={{display:'flex',justifyContent:'space-between'}}>
            <Typography align="center" gutterBottom variant="h6" className="AssignedStaffHeading">
            Mobile
              </Typography>
              <Typography align="center" gutterBottom variant="h6" className="AssignedStaffHeading-content">
                  +{salesManagerData?.attributes?.phone_number}
              </Typography>
            </Box>
            </div>
          </Card>
    {/* </Box> */}
  </Box>
          </Grid>
          </div>
          <div style={{display:'flex',width:'100%'}}>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              className="Title LightcolorText">
              Application executive 
            </Typography>
          </Grid>
          <Grid item xs={6}>
          {universityStaffData && universityStaffData.map((staffItem:any) => (
<Box key={staffItem.id} className="VisitonStaffContainer">
        <Card className="CardOfAssignedStaff" style={{width:'100%',boxShadow:'none',marginBottom:'20px'}}>
            <Box className="StaffLogoContainer">
              <img
                className="StaffLogo"
                src={staffItem?.attributes?.image || defaultImg}
                alt="Staff"
              />
            </Box>
            <div style={{width:'100%'}}>
            <Box className="FirstNameLastNameContainer" style={{display:'flex',justifyContent:'space-between'}}>            <Typography align="center" gutterBottom variant="h6" className="AssignedStaffHeading">
               Name
              </Typography>
              <Typography align="center" gutterBottom variant="h6" className="AssignedStaffHeading-content">
                {staffItem?.attributes?.first_name} {staffItem?.attributes?.last_name}
              </Typography>
            </Box>
            <Box className="FirstNameLastNameContainer" style={{display:'flex',justifyContent:'space-between'}}>            <Typography align="center" gutterBottom variant="h6" className="AssignedStaffHeading">
            Designation
              </Typography>
              <Typography align="center" gutterBottom variant="h6" className="AssignedStaffHeading-content">
              {staffItem?.attributes?.role}
              </Typography>
            </Box>
            <Box className="FirstNameLastNameContainer" style={{display:'flex',justifyContent:'space-between'}}>            <Typography align="center" gutterBottom variant="h6" className="AssignedStaffHeading">
            Mobile
              </Typography>
              <Typography align="center" gutterBottom variant="h6" className="AssignedStaffHeading-content">
                  +{staffItem?.attributes?.phone_number}
              </Typography>
            </Box>
            </div>
          </Card>
  </Box>
))}
          </Grid>
          </div>
        </Grid>
      </Grid>
    </Grid>
    {this.ReAssigneModel()}
  </Box>
    );
  }
  RenderModel = () => {
    const { UploadDocumentAPIData, UploadFIle, FileSize } = this.state

    const UploadFileLenghIsZero = UploadFIle.length === 0
    const IsOtherDoucment = this.state.UploadDocumentAPIData.APIKey === "Other Documents"
    return (
      <UploadModalBox maxWidth='lg' onClose={this.CloseUploadModel} aria-labelledby="simple-dialog-title" open={this.state.IsUploadDoumentModal}>
        <Box className="CloseIconContainer">
          <IconButton data-test-id="CloseUploadModel" onClick={this.CloseUploadModel}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography gutterBottom variant="h6" className="ModalHeadig">
          {UploadDocumentAPIData.APIKey}
        </Typography>
        <DialogContent>
          <Box className="FileFormatAndFileSizeContainer">
            <Typography gutterBottom variant="h6" className="FileFormatHeading">
              Accepted file format: <span className="FilesName" >.png, .jpg, .pdf ,.doc/.docx</span>
            </Typography>
            <Typography gutterBottom variant="h6" className="FileFormatHeading">
              Accepted max file size: <span className="FilesName" >10MB</span>
            </Typography>
          </Box>
          {UploadFileLenghIsZero ?
            <Box component="label" style={{ display: `${!UploadFileLenghIsZero ? "none" : ''}` }} data-test-id="UploadAddDoumentButton" className={`DragAndDropContainer`}>
              <Box>
                <Box className={"DragAndDropIconContainer"}>
                  <img src={DragAndDrop} className="DragAndDropIcon" /> :
                </Box>
                <Box>
                  <Typography variant="h6" className="DragAndDropheading">
                    Drag and drop files
                  </Typography>
                  <Typography variant="h6" className="DragAndDropheading">
                    or
                  </Typography>
                </Box>
                <Box className="UploadButtonContainer">
                  <input
                    type="file"
                    data-test-id="UploadAddDoumentInput"
                    onChange={(event) => {
                      this.UploadFileMultipleFiles(event)
                    }}
                    multiple={this.state.UploadDocumentAPIData.APIKey === "Other Documents"}
                    className="InputHidden"
                    id="UploadAddDoumentCard"
                  />
                  <Button component="span" variant="contained" className="AddDocumentButton">
                    Choose a file to upload
                  </Button>
                </Box>
              </Box>
            </Box> :

            UploadFIle.map((items, index) => {
              return (
                <>
                  <Box className={`DragAndDropContainer  "BgChange"`}>
                    <Box>
                      <Box className={"DragAndDropIconContainer"}>
                        <Box>
                          <Box className="RenderIcon">
                            {this.getIconForFileType(items?.name.split(".")[1])}
                          </Box>
                          <Box>
                            <Typography variant="h6" className="DragAndDropheading" style={{ color: "#0F172A" }}>
                              {items?.name}
                            </Typography>
                            <Typography variant="h6" className="DragAndDropheading">
                              {FileSize[index]}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Box className="UploadButtonContainer">
                          <Button startIcon={<DeleteForever color="error" />}
                            onClick={() => { this.OnDeleteUpload(index) }}
                            component="span" variant="contained" data-test-id={"DeleteDocBtn" + index} className="AddDocumentButton DeleteButton">
                            Delete file uploaded
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Typography gutterBottom variant="h6" className="RemarkHeading" color="error">
                    {this.state.UploadErrorText[index]}
                  </Typography>
                </>
              )
            })
          }
          {
            !IsOtherDoucment &&
            <Typography gutterBottom variant="h6" className="RemarkHeading" color="error">
              {this.state.UploadErrorText[0]}
            </Typography>
          }
           {
            this.state.UploadDocumentAPIData.APIKey === "Other Documents" &&
            <Box className="RemarkContainer">
              <Typography gutterBottom variant="h6" className="RemarkHeading">
                Remarks
              </Typography>
              <TextField
                placeholder={"Write something here..."}
                className="inputField"
                onChange={(e) => {
                  this.RemarkOnChange(e)
                }}
                value={this.state.RemarkText}
                name={"FirstName"}
                fullWidth
                data-test-id="RemarksTextfield"
                multiline
                minRows={8}
              />
            </Box>
          }
          {UploadDocumentAPIData.field_label && 
          <>
                    <Typography
        variant="subtitle1"
        style={{ fontWeight: 'bold', color: '#1A202C', marginBottom: '8px' }}
      >
        {UploadDocumentAPIData.field_label}
      </Typography>
        <TextField
            placeholder={UploadDocumentAPIData.field_placeholder}
            value={this.state.inputValue}
            onChange={this.handleInputChange}
            variant="outlined"
            fullWidth
            style={{
              borderRadius:'10px',
            }}
            />
          </>
          }
          <Box className='SaveChagesButtonContainer' style={{marginTop:'20px'}}>
            <Button component="span" data-test-id="SaveButtonDocument" variant="contained" onClick={()=>this.OnSaveUploadDocument()} className="SaveChangesButton">
              Save changes
            </Button>
          </Box>
        </DialogContent>
      </UploadModalBox>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ViewMoreStyle>
        <Loader loading={this.state.loading} />
        <LeadBox>
          <AppHeader isLogin={false}
            data-test-id="appHeaderTestId"
            handleToggle={this.handleToggle}
            toggleData={this.state.toggleDrawer}
            onHomeClick={this.onHomeClick}
            isUserLogin={true}
            userName={this.state.userDetails?.attributes?.first_name}
            role={this.state.userDetails?.attributes?.role}
            profileImg={this.state.userDetails?.attributes?.image}
          />
          <div style={{display:'flex'}}>
          <SideMenuBar data-test-id="sidebarNavigationBtn"
            navigationToAnyPage={this.navigationToAnyPage} activeComponent="LeadManagement" />
            <BoxWrapper>
            <Box className="mainBar-wrap" style={{display:'flex',paddingLeft:'50px'}}>
            <Button
  className="button-hide-bar"
  onClick={this.toggleVisibility}
  variant="contained"
  startIcon={
    this.state.isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />
  }
>
  {this.state.isVisible ? "Hide" : "Show"}
</Button>
        {this.state.isVisible && (
          <Box 
            className="mainBar-sidemenu" 
            style={{padding: '24px' }}
          >
            <BoxStepper>
              {this.coversationRender()}
            </BoxStepper>
          </Box>
        )}
          <Box className="mainBar">
            {this.showErrorToast()}
            <Box>
                {this.ViewStudentDetailsCard()}
              <Box className="StudentDetails-tabss">
                <Card className="StudentDetailsContainer TabsContainer">
                  <Box className="StundetGenralDetails">
                    <Box className="ButtonAndTabsContainer">
                      <Tabs value={this.state.ActiveTabIndex}
                        indicatorColor="primary"
                        variant="scrollable"
                        textColor="primary"
                        scrollButtons="auto"
                        onChange={this.HandelTabChange}
                        className="TabsOfViewProfile tabTube"
                        data-test-id="TabsContainer"
                        aria-label="scrollable auto tabs example" >
                        <Tab label={`Applicant Requirements (${this.state.applicationReq.total_requirements})`} />
                        <Tab label={`Institute Documents (${this.state.allDocuments.total_documents})`} />
                        <Tab label={`Conversation (${this.state.convCount})`} />
                        <Tab label="Eligiblity" />
                        <Tab label={`Purchase Services (${this.state.otherService.length})`}/>
                      </Tabs>
                    </Box>
                  </Box>
                </Card>
                <Box>
                  {this.RenderActiveTab(this.state.ActiveTabIndex)}
                </Box>
              </Box>
            </Box>
          </Box>
          </Box>
            </BoxWrapper>
          </div>
        </LeadBox>
        {this.RenderModel()}
        {this.renderStudentModal()}
        {this.renderSuccessModal()}
        <ApplicationsConversation sendDataCount={this.UpadtedCount} navigation={null}  />
      </ViewMoreStyle>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ModalBox = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& .innerModalBox": {
      maxWidth: "680px",
      position: "relative",
      borderRadius: "8px",
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
      padding: "20px 50px",
      backgroundColor: "#fff",
      marign: "20px !important",
      "@media (max-width: 500px)": {
          padding: "15px"
      },
  },
  "& .closeIcon": {
      position: "absolute",
      right: "10px",
      top: "10px",
      cursor: "pointer",
      color: "#334155",
      '&:hover': {
          backgroundColor: "rgba(255, 255, 255, 0.3)"
      }
  },
  "& .gapBox": {
      display: "flex",
      gap: "20px",
      padding: "20px",
      flexDirection: "column",
      "@media (max-width: 768px)": {
          gap: "15px",
          padding: "10px",
      },
  },
  "& .modalTitle": {
      fontFamily: "Plus Jakarta Sans",
      fontSize: "18px",
      fontWeight: 700,
      color: "#000000",
      "@media (max-width: 768px)": {
          fontSize: "16px",
      },
  },
  "& .modalDescription": {
      fontFamily: "Plus Jakarta Sans",
      fontSize: "14px",
      fontWeight: 400,
      color: "#000000",
      "@media (max-width: 768px)": {
          fontSize: "12px",
      },
  },
  "& .middleBox": {
      display: "flex",
      borderRadius: "8px",
      backgroundColor: "#F8FAFC",
      alignItems: "center",
      gap: "10px",
      padding: "15px 20px",
      "@media (max-width: 768px)": {
          padding: "15px",
          gap: "5px",
      },
  },
  "& .modalImgBox": {
      height: "40px",
      width: "40px",
      borderRadius: "50%",
  },
  "& .modalHeadingTxt": {
      color: "#2A0066",
      fontFamily: "Plus Jakarta Sans",
      fontSize: "16px",
      fontWeight: 700,
      "@media (max-width: 768px)": {
          fontSize: "14px",
          textAlign: "center",
      },
  },
  "& .btnBox": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
  },
  "& .openAccountBtn": {
      color: "#FFFFFF",
      height: "28px",
      padding: "10px 5px",
      borderRadius: "8px",
      textTransform: "none",
      backgroundColor: "#34D399",
      width: "270px",
      fontSize: "14px",
      "@media (max-width: 768px)": {
          width: "100%",
          fontSize: "12px"
      },
      "&:hover":{
        backgroundColor: "#34D399",
      }
  },
  "& .studentBox": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "@media (max-width: 768px)": {
          gap: "10px"
      }
  },
  "& .modalStudentTxt": {
      color: "#334155",
      fontFamily: "Plus Jakarta Sans",
      fontSize: "14px",
      fontWeight: 700,
      "@media (max-width: 768px)": {
          fontSize: "12px",
      },
  },
  "& .addStudentBtn": {
      color: "#34D399",
      textTransform: "unset",
      fontFamily: "Plus Jakarta Sans",
      fontSize: "12px",
      fontWeight: 700,
  }
});

const SuccessModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& .innerSuccessModalBox": {
      position: "relative",
      maxWidth: "680px",
      borderRadius: "8px",
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
      padding: "20px 50px",
      marign: "20px !important",
      backgroundColor: "#fff",
      "@media (max-width: 500px)": {
          padding: "15px",
      },
  },
  "& .closeSuccessIcon": {
      position: "absolute",
      top: "10px",
      right: "10px",
      color: "#334155",
      cursor: "pointer",
      '&:hover': {
          backgroundColor: "rgba(255, 255, 255, 0.3)",
      }
  },
  "& .gapSuccessBox": {
      gap: "20px",
      display: "flex",
      padding: "20px",
      flexDirection: "column",
      alignItems: "center",
      "@media (max-width: 768px)": {
          gap: "15px",
          padding: "10px",
      },
  },
  "& .requestTxt": {
      fontFamily: "Plus Jakarta Sans",
      fontSize: "20px",
      fontWeight: 700,
      color: "#000000",
      "@media (max-width: 768px)": {
          fontSize: "18px",
      },
  },
  "& .idTxt": {
      fontFamily: "Plus Jakarta Sans",
      fontSize: "16px",
      fontWeight: 400,
      color: "#000000",
      "@media (max-width: 768px)": {
          fontSize: "16px",
      },
  },
  "& .idSpanTxt": {
      marginLeft: "5px",
      color: "#000000",
      fontFamily: "Plus Jakarta Sans",
      fontSize: "16px",
      fontWeight: 700,
  },
  "& .okBtn": {
      color: "#FFFF",
      textTransform: "unset",
      fontFamily: "Plus Jakarta Sans",
      fontSize: "15px",
      fontWeight: 700,
      backgroundColor: "#34D399",
      width: "150px",
      "&:hover": {
          backgroundColor: "#26B685",
      }

  },
  "& .btnBox": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
  }
})

const ContentBox = styled(Box)({
  display:"flex"
})

const MainBox = styled(Box)({
  width: "100%",
  padding: "18px 18px 18px 16px",
  boxSizing: "border-box",
  maxWidth: "100%",
  backgroundColor: "#ffffff",
  "@media (max-width: 500px)": {
      padding: "20px"
  },
  "& .headignTxt": {
      fontWeight: 700,
      fontSize: "22px",
      fontFamily: "Plus Jakarta Sans",
      "@media (max-width: 768px)": {
          fontSize: "18px"
      }
  },
  "& .CustamClass":{
      border:"1px solid #E2E8F0",
      "& .Servicesprovider":{
          height: "unset !important",
          minHeight: "unset !important",
          maxHeight: "unset !important",
          "& span":{
              fontSize: "16px",
              color: "#2A0066",
              fontWeight: "800"
          },
          "& .ServicesproviderName":{
              fontSize: "14px",
          }
      }
  },
  "& .cardMainBox": {
      padding: "25px",
      backgroundColor: "#FFFFFF",
      borderRadius: "8px",
      gap: "20px",
      display: "flex",
      flexDirection: "column",
      minHeight: "200px",
      maxHeight: "200px",
      position:"relative",
      "@media (max-width: 768px)": {
          padding: "15px"
      },
      "& .OpenAccountClass":{
          position: "absolute",
          bottom: "20px",
          color: "#23CF93",
  width: "160px",
  border: "1px solid #23CF93",
  height: "28px",
  padding: "6px 10px",
  borderRadius: "8px",
      }
  },
  "& .headingBox": {
      display: "flex",
      alignItems: "center",
      gap: "10px"
  },
  "& .cardLogoImg": {
      height: "40px",
      width: "40px",
      borderRadius: "50%",
      backgroundColor: "#F8FAFC",
      objectFit: "cover",
      display: "block"
  },
  "& .cardHeadingTxt": {
      color: "#2A0066",
      fontFamily: "Plus Jakarta Sans",
      fontSize: "18px",
      fontWeight: 700,
      lineHeight:"26px",
      "@media (max-width: 768px)": {
          fontSize: "14px"
      }
  },
  "& .descriptionTxt": {
    fontFamily: "Plus Jakarta Sans",
        fontSize: "12px",
        color: "#000000",
        overflow: "auto",
        whiteSpace: "pre-wrap",
        wordBreak: "break-word",
        height: "50px",
        minHeight: '50px',
        maxHeight: '50px',
      "@media (max-width: 768px)": {
          fontSize: "10px"
      },
      "&::-webkit-scrollbar": {
          width: "12px"
      },
      "&::-webkit-scrollbar-track": {
          background: "#f1f1f1"
      },
      "&::-webkit-scrollbar-thumb": {
          background: "#888",
          borderRadius: "10px"
      },
      "&::-webkit-scrollbar-thumb:hover": {
          background: "#555"
      },
      "&::-moz-scrollbar": {
          width: "12px"
      },
      "&::-moz-scrollbar-track": {
          background: "#f1f1f1",
      },
      "&::-moz-scrollbar-thumb": {
          background: "#888",
          borderRadius: "10px",
      },
      "&::-moz-scrollbar-thumb:hover": {
          background: "#555",
      }
  }
});

const ViewMoreBtn = styled(Button)({
  "@media(max-width:800px)": {
      width: "100%"
  },
  "& .viewMoreTxt": {
      fontFamily: "Plus Jakarta Sans",
      fontSize: "14px",
      fontWeight: 700,
      textTransform: "none",
      "@media (max-width: 768px)": {
          fontSize: "12px"
      }
  }
});

const BoxStepper  = styled(Box)({
  "h6":{
    fontFamily:'Plus Jakarta Sans !important',
    fontWeight:'700',
    fontSize:'15px',
  },
  "&.MuiStepLabel-label.MuiStepLabel-active":{
    color:'#059669 !important',
  },
  "& .MuiStep-horizontal":{
    marginBottom:'13px',
  },
    "& .MuiStep-completed":{
      "& h6":{
        color:'#059669 !important',
      },
    },
    "& .MuiStepLabel-label.MuiStepLabel-active,.successStp":{
       color:'rgba(0, 0, 0, 0.54) !important',
    },
    "& .MuiStepper-alternativeLabel": { flexDirection:'column',left:'18px !important'},
    "& .MuiStepConnector-horizontal":{left:'19px !important',right:'0',top:'-19px'},
    "& .MuiStepConnector-horizontal .SteperHeading":{fontFamily:'Plus Jakarta Sans !important'},
    "& .MuiStepLabel-root.MuiStepLabel-alternativeLabel":{flexDirection:'row !important' },
    "& .MuiStepConnector-lineHorizontal":{height:'50px',width:'4px'}
})

const useColorlibStepIconStyles = makeStyles({
    root: {
      zIndex: 1,
      color: "#fff",
      width: 24,
      height: 24,
      display: "flex",
      backgroundColor: "#fff",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      border: "1px solid #34D399",
    },
    active: {
      // backgroundColor: "#34D399"
    },
    completed: {
      backgroundColor: "#34D399"
    },
    "& .MuiStepLabel-alternativeLabel": {
      paddingLeft: "0px",
      paddingRight: "0px",
      flexDirection:'row'
    },
  });

function SuccessColorlibStepIcon(props: StepIconProps) {
    const classes = useColorlibStepIconStyles();
    const {
      active,
      completed
    } = props;
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
        style={{marginTop:'16px',marginRight:'16px'}}
      >
        {completed || active ? (
          <CheckIcon />
        ) : (
          <div style={{ width: "10px", height: "10px", borderRadius: "50%" }} />
        )}
      </div>
    );
  }

const SuccessColorlibConnector = withStyles({
    alternativeLabel: { top: 10, },
    active: {
      '& $line': { backgroundColor: '#34D399', },
    },
    completed: {
      '& $line': { backgroundColor: '#34D399', },
    },
    line: {
      height: 7,
      border: 0,
      backgroundColor: '#D9D9D9',
      borderRadius: 1,
    },
  })(StepConnector);


const AccordionWrapper= styled(Box)({
    "& .MuiExpansionPanel-rounded":{
        padding:'20px',
    },
    "& .DeleteIconButtonClass": {
      color: 'red',
  border: '1px solid',
  borderRadius: '9px',
  padding: '3px',
    },
    "& .expandable-bar .MuiExpansionPanelSummary-root":{
      paddingLeft:'0',
    },
    "& .expandable-bar .MuiExpansionPanel-rounded":{
      paddingTop:'0', paddingLeft:'0',boxShadow:'none',
    },
    "& .expandable-bar .MuiExpansionPanelSummary-content":{
      margin:'0',
      maxHeight:'39px',
    },
    "& .MuiExpansionPanelSummary-content":{
        justifyContent:'space-between',
        "& h6":{
            fontSize:'18px',
            fontWeight:600,
            fontFamily: 'Plus Jakarta Sans',
        },
        "& p":{
            color:'#64748B',
            fontSize:'14px',
        },
    },
    "& .MuiExpansionPanelSummary-expandIcon":{
        position:'absolute',
        left:0,
            },
    "& .MuiExpansionPanelSummary-expandIcon.Mui-expanded":{
        transform:'rotate(213deg)'
            },
    "& .MuiExpansionPanelSummary-root":{
        paddingLeft:'50px',
        border:'1px solid #E2E8F0',
        borderRadius:'8px',
            },
    "& .internal-summary":{
      border:'none',
    } ,       
    "& .internal-summary h6":{
        color:'#2A0066',
    },
    "& .download-box":{
        border: '1px solid #E2E8F0',
        borderRadius: '10px',
        padding: '10px 11px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '259px',
        whiteSpace: 'nowrap',
      },  
      "& .download-new":{
        overflow: 'unset',
        display:'flex',
        alignItems:'center',
        border:'none',
        background:'#d4d6d94f',
        maxWidth:'335px',
        justifyContent:'space-around',
        "& .downtext":{
            textOverflow: 'ellipsis',
            display: 'inline-block',
            maxWidth: '167px',
            whiteSpace: 'nowrap',
            overflow:'hidden',
            marginLeft:'10px',
        }
      } ,   
})

const ExpandMoreIconNew = styled(Box)({
    width: '0', 
    height: '0', 
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent', 
    borderRight:'16px solid #000', 
    transform:'rotate(180deg)',
})

const ViewMoreStyle = styled(Box)({
  "& .HeadingEmergency": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '30.24px',
  },
  "& .MobileNumberTextFieldContainer": {
    width: "100%"
  },
  "& .AddAnother": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: 400,
    color: '#34D399',
    textTransform: "capitalize",
  },
  "& .selectField": {
    "& .MuiSelect-select.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.38)"
    },
    "& :hover": {
      borderBottom: "0px solid red"
    }
  },
  "& .ContantryAndDateClass": {
    display: "flex",
    gap: "5px",
    "& .Title": {
      marginTop: "2px !important",
      marginBottom: "2px !important"
    },
    "& .ReadMore": {
      marginLeft: "20px",
      cursor: "pointer",
    }
  },
  "& .BoxContainer": {
    width: "100%",
    marginBottom: "14px",
    "& .StudentIdText": {
      textTransform: "capitalize",
      fontSize: "16px",
      fontFamily: 'Plus Jakarta Sans',
      color: "#64748B",
      "@media (max-width: 425px)": {
        fontSize: "12px",
      },
      "& .MuiSvgIcon-root": {
        fontSize: "16px"
      },
      "& .BoldText": {
        fontWeight: "bold",
        color: "#475569",
        textTransform: "uppercase",
      }
    }
  },
  "& .StudentDetailsContainer": {
    boxShadow:'none',
    padding: '20px',
    "& .StundetGenralDetails": {
        paddingTop:'20px',
        paddingBottom:'20px',
      "& .ViewStudentHeadig": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '18px',
        fontWeight: '700',
        lineHeight: '26px',
        textTransform: "capitalize"
      },
      "& .ProfilePicContainer": {
        marginRight:'3%',
        "& .StudentProfilePic": {
          widht: "96px",
          height: "96px",
          borderRadius: "50%",
          maxWidth: "96px",
          "@media (max-width: 425px)": {
            marginBottom: "10px"
          }
        }
      },
      "& .EditProfile": {
        display: "flex",
        alignItems: "center",
        "& .ProfileUpdateButton": {
          maxHeight: "30px",
          height: '30px',
          marginLeft: "20px",
          border: '1px 0px 0px 0px',
          fontWeight: '800',
          textTransform: 'capitalize'
        },
        "& .ErrorText": {
          color: "red"
        },
      },
      "& .DetailsCotainer": {
        "& .DetailsBox": {
          display: 'flex',
          gap: "10px",
          "& .GreyColorText": {
            color: "#64748B"
          },
          "& .MuiTypography-body1": {
            fontFamily: 'Plus Jakarta Sans',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '24px',
            textAlign: 'left'
          }
        }
      },
      "& .TabsOfViewProfile": {
        border:'1px solid #34D399',
        borderRadius:'45px',
        maxWidth:'595px',
        "& .PrivateTabIndicator-colorPrimary-45":{
            display:'none',
        },
        "& .MuiTabScrollButton-root":{
          display:'none',
        },
        "@media (max-width: 425px)": {
          width: "100%"
        },
        "& .MuiTabs-flexContainer": {
        },
        "& .MuiTabs-indicator": {
          display:'none',
        },
        "& .MuiTab-wrapper": {
          color: 'black',
          fontSize: '14px',
          fontWeight: 400,
          textTransform: 'capitalize',
          "@media (max-width: 600px)": { fontSize:'10px' },
        },
        "& .Mui-selected": {
          background:'#D1FAE5',
          borderRadius:'45px',
          padding:'3px 17px',
          fontSize:'12px',
          minWidth:'128px',
          "@media (max-width: 768px)": { 
            minWidth:'88px',
            maxWidth:'88px',
           },
           "@media (max-width: 468px)": { 
            minWidth:'48px',
            maxWidth:'48px',
           },
          "& .MuiTab-wrapper": {
            color: 'black',
            fontWeight: 700
          },
        }
      },
      "& .tabtube":{
        width:'100%',
        maxWidth:'none',
        "& .PrivateTabIndicator-colorPrimary-45":{
            display:'none',
        },
      },
    },
    "& .SecondDetailsContainer": {
      display: 'flex',
      justifyContent: 'space-between',
      "@media (max-width: 425px)": {
        display: 'block',
      },
      "& .IconAndDetailsContainer": {
        display: "flex",
        gap: '10px',
        alignItems: "center",
        marginBottom: "4px",
        "& .DarkTextColor": {
          color: "#0F172A"
        }
      },
      "& .EditButton": {
        backgroundColor: '#D6F6EB',
        border: '1px solid #34D399',
        color: '#059669',
        textTransform: 'capitalize',
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '700',
        textAlign: 'center',
        padding: "5px 8px",
        borderRadius: "6px"
      },
      "& .SaveChanges": {
        color: 'white',
        backgroundColor: "#34D399"
      }
    },
    "& .EditDetailsContainer": {
      justifyContent: 'flex-end',
    }
  },
  "& .PersonalInfoContainer": {
    marginTop: "20px",
    // textfield styles
    "& .inputField": {
      backgroundColor: "#fff",
      width: "100%",
      borderRadius: "8px",
      height: "50px",
      margin: "5px 0px",
      display: "flex",
      justifyContent: "center",
      padding: "0px 10px",
      boxSizing: "border-box",
      "& .MuiInput-root": {
        "&:focus": {
          outline: "none"
        },
        "&::before": {
          content: "none"
        },
        "&::after": {
          content: "none"
        }
      }
    },
    // textfield style end

    "& .VisitonStaffContainer":{
        display:'flex',
        "& .StaffLogo":{
            borderRadius:'50%',
            width:'60px',
            height:'60px',
            marginRight:'15px',
            "@media (max-width: 600px)": { width:'25px',
            height:'25px',
            marginRight:'5px',
           },
        },
        "& h6":{
            fontSize:'14px',
            "@media (max-width: 600px)": { 
              fontSize:'10px',
           },
        },
        "& .AssignedStaffHeading-content": { fontWeight:'700' },
    },
    "& .CardOfAssignedStaff":{
        display:'flex',
    },

    "& .PersonalInfoGridContainer": {
      "& .Section": {
        padding: "0 15px"
      },
      "& .DetailsContainer": {
        paddingRight: "140px",
        "@media (max-width: 768px)": {
          paddingRight: "10px",
        },
        "& .LightcolorText": {
          color: "#64748B"
        },
        "& .MuiGrid-item": {
          borderBottom: '1px solid #E2E8F0',
          "& .Title": {
            marginBottom: "10px",
            marginTop: "10px",
            "@media (max-width: 600px)": { fontSize:'10px'},
          },
          "& .BoldTitle": {
            fontWeight: "bold",
            textTransform: "capitalize",
            "@media (max-width: 600px)": { fontSize:'10px' },
          }
        },
        "& .NameAndDetails": {
          display: "flex",
          gap: "5px",
          alignItems: "center",
          "& .smallTitle": {
            minWidth: "70px",
            "@media (max-width: 600px)": { fontSize:'10px' },
          }
        }
      }
    }
  },
  "& .ReassignapplicationBtn": {
    color: '#059669',
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: 700,
    textTransform: "capitalize",
    borderRadius: "6px",
    paddingLeft: "14px",
    paddingRight: "14px",
    minHeight: "40px",
    fontSize:'14px',
  },
  "& .TabsContainer": {
    marginTop: "30px",
    padding: "0px",
    "& .ButtonAndTabsContainer": {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin:'0 15px',
      "@media (max-width: 768px)": {
        display: 'flex',
        flexDirection: 'column-reverse',
        alignItems: 'flex-start',
      },
    },
    "& .DownloadDocumentsButton": {
      backgroundColor: "#373335",
      color: 'white',
      marginRight: "20px",
      fontFamily: 'Plus Jakarta Sans',
      fontWeight: 700,
      textTransform: "capitalize",
      borderRadius: "6px",
      paddingLeft: "14px",
      paddingRight: "14px"
    },
  },
  "& .EditForm": {
    "& .CountryDateContainer": {
      display: "flex",
      alignItems: "center",
      "& .DeleteIconContainer": {
      },
    },
    "& .PersonalInfoContainer": {
      padding: "20px"
    }
  },
  "& .RenderTab": {
    backgroundColor: "white"
  },
  "& .TabDetailsContainer": {
    backgroundColor: "#F8FAFC",
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 0px 8px',
    padding: "20px",
    "& .Container": {
      marginBottom: "30px"
    },
    "& .InputForStudentDoc": {
      display: "none"
    },
    "& .StudentDetailsHeadig": {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '18px',
      fontWeight: '700',
      lineHeight: '26px',
      textTransform: "capitalize",
      marginBottom: "20px"
    },
    "& .AddDoumentCard": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "2px dotted #CBD5E1 !important",
      height: "100%",
      boxShadow: "none",
      minHeight: "170px",
      padding: "0px !important",
      "& .AddDocumentButton": {
        color: "#34D399",
        border: "1px solid #34D399",
        borderRadius: "6px",
        textTransform: "capitalize",
        backgroundColor: "white",
        boxShadow: "none"
      },
      "& .Mui-disabled": {
        color: "#64748B !important",
        backgroundColor: "#F1F5F9 !important",
        border: "none !important",
      }
    },
    "& .DoucmentCard": {
      padding: '20px',
      borderRadius: '8px',
      border: '4px',
      minWidth: "220px",
      "@media (max-width: 425px)": {
        minWidth: "unset",
      },
      "& .IconContainer": {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        "& .DeleteIconButtonClass": {
          backgroundColor: "#FEE2E2",
          color: "red"
        },
        "& .MuiButtonBase-root": {
          padding: "0px",
        }
      },
      "& .DocumentHeadingContainer": {
        marginTop: "20px",
        marginBottom: "20px"
      },
      "& .DoumentHeading": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '18px',
        textAlign: 'left'
      },
      "& .DoumentSize": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '18px',
        textAlign: 'left',
        color: "#64748B"
      },
      "& .DownloadButtonContainer": {
        marginTop: "20px",
        display: "flex",
        "& .DownloadButton": {
          borderColor: "#64748B",
          color: "#64748B",
          borderRadius: "6px",
          textTransform: "capitalize"
        }
      },
      "& .ComentClassContainer": {
        display: "flex",
        marginTop: "10px",
        alignItems: "center",
        gap: "4px",
        "& .ComentClass": {
          fontFamily: 'Plus Jakarta Sans',
          fontSize: '12px',
          fontWeight: 700,
          textAlign: 'left',
        }
      }
    }
  },
})

const ShortListStyle = styled(Box)({
  height: "100%",
  paddingRight: "0px !important",
  padding: "0px !important",
  "& .NoDataSectionClass": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .IconDetailsContainer": {
      marginTop: "100px",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      "& .width40": {
        width: "40%",
        "@media (max-width: 768px)": {
          width: "90%",
        },
      },
      "& .IconContainer": {
        display: "flex",
        justifyContent: "center",
        "& .Icon": {
          backgroundColor: "#F8FAFC",
          height: "120px",
          width: "120px",
          borderRadius: "50px",
          "& .MuiSvgIcon-root": {
            fontSize: "100px",
            color: "lightgrey"
          }
        }
      },
      "& .ButtonContainer": {
        display: "flex",
        gap: "20px",
        marginTop: "20px",
        "@media (max-width: 768px)": {
          display: "block",
          "& button": {
            marginTop: "10px",
            marginBottom: "10px"
          }
        },
      }
    }
  },
  "& .ShortListDataRender": {
    borderBottom: "1px solid #E2E8F0",
    padding: "25px 0",
    "& .InlinePadingClass": {
      paddingInline: "25px",
    },
    "& .LogoAndTextContainer": {
      display: "flex",
      "@media (max-width: 768px)": {
        display: "block",
      },
      "& .LogoContainer": {
        "@media (max-width: 768px)": {
          display: "block",
          padding: "0 0px",
        },
        padding: "0 20px",
        "& .UniversityLogo": {
          width: '100px',
          height: '100px',
          borderRadius: "50%",
          backgroundColor: "white"
        }
      },
      "& .UniversityTextContainer": {
        "& .UniversityAndLocationContainer": {
          display: "flex",
          gap: "40px",
          "@media (max-width: 768px)": {
            display: "block",
          },
          "& .UniversityIconAndText": {
            display: "flex",
            "& .SmallIcon": {
              marginRight: "14px"
            }
          }
        }
      }
    },
    "& .ApplyNoeContainer": {
      display: "flex",
      justifyContent: "flex-end",
      "@media (max-width: 768px)": {
        justifyContent: "flex-start",
      },
    },
    "& .SecondContainer": {
      marginTop: "30px",
      marginBottom: "30px",
      borderTop: "1px solid #B6373C",
      borderBottom: "1px solid #B6373C",
      padding: "20px",
      "& .IntakeLabelContainer": {
        display: "flex",
        alignItems: "baseline",
        gap: "20px",
        justifyContent: "center",
        "@media (max-width: 768px)": {
          justifyContent: "flex-start",
        },
      },
      "& .IntakeLabelButton": {
        backgroundColor: '#D6F6EB',
        border: '1px solid #34D399',
        color: '#059669',
        textTransform: 'capitalize',
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: '700',
        textAlign: 'center',
        borderRadius: "6px",
        minWidth: "120px",
        minHeight: "32px"
      },
      "& .ToBeOpenedClass": {
        backgroundColor: "#FEF3C7",
        color: "#D97706",
        borderColor: "#D97706"
      },
      "& .ClosedClass": {
        backgroundColor: "#FFD5D5",
        color: "#DC2626",
        borderColor: "#DC2626"
      }
    },
    "& .ThirdContainer": {
      padding: "0 20px",
      display: "flex",
      gap: "10px",
      "@media (max-width: 425px)": {
        display: "block",
      },
      "@media (max-width: 768px)": {
        padding: "0 0px",
        display: "block",
      },
      "& .TextDetailsContainer": {
        padding: "5px"
      },
      "& .DetailsHeading": {
        fontFamily: 'Plus Jakarta Sans', fontSize: '12px', fontWeight: '700', lineHeight: '26px', textTransform: "uppercase",
      },
      "& .DetailsSubHeading": {
        fontFamily: 'Plus Jakarta Sans', fontSize: '16px', fontWeight: '400', lineHeight: '26px', textTransform: "capitalize",
      }
    }
  },
  "& .ShortListHeadig": {
    fontFamily: 'Plus Jakarta Sans', fontSize: '18px', fontWeight: '700', lineHeight: '26px', textTransform: "capitalize",
  },
  "& .Nocourses": {
    marginBottom: "20px"
  },
  "& .ShortListSubHeadig": {
    fontFamily: 'Plus Jakarta Sans', fontSize: '18px', fontWeight: '400', lineHeight: '26px', textTransform: "capitalize",
    textAlign: "center"
  },
  "& .AskExpertBtn": {
    backgroundColor: '#D6F6EB',
    border: '1px solid #34D399',
    color: '#059669',
    textTransform: 'capitalize',
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
    textAlign: 'center',
    padding: "5px 8px",
    borderRadius: "6px"
  },
  "& .ShortlistBtn": {
    color: 'white',
    backgroundColor: "#34D399",
    "&:hover": {
      backgroundColor: "#34D399",
      boxShadow: "none"
    },
    "& .OpenClass": {
      backgroundColor: '#D6F6EB',
      border: '1px solid #34D399',
      color: '#059669',
      textTransform: 'capitalize',
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '24px',
      textAlign: 'center',
      padding: "5px 8px",
      borderRadius: "4px"
    }
  }
})

const LeadBox = styled(Box)({
  backgroundColor: "#EEEEEE",
  fontFamily: "Plus Jakarta Sans",
  "& .mainBar": {
    padding: "28px 50px 28px 100px",
    maxWidth:'904px',
    "@media (max-width: 1280px)": {
      maxWidth:'734px',
    },
    "@media (max-width: 600px)": {
      padding: "20px",
      maxWidth:'510px',
      overflow:'auto',
    },
    "@media (max-width: 500px)": {
      maxWidth:'366px',
    },
    "@media (max-width: 400px)": {
      maxWidth:'260px',
    },
  },
});
function RadioButton(props: RadioProps) {
  return (
    <CustomizeRadio
      disableRipple
      color="default"
      checkedIcon={<CheckCircle />}
      {...props}
    />
  );
}

const FileUploadErrorText = styled(Typography)(({ theme }) => ({
  maxWidth:'385px',
  marginTop:'-15px',
  right:0,
  position:'absolute',
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "18px",
  textAlign: "left",
  color: "#BB393D",
  padding: "5px",
  width:"100%",
  [theme.breakpoints.down("sm")]: {
    fontSize: "8px",
    padding: "8px",
    width:"45%",
  },
}));

const StatusChip = styled(Chip)(({ theme, className }) => ({
  ...(className === 'InprogressClass' && {
    backgroundColor: '#FEF3C7 !important', 
    color: '#D97706 !important',           
  }),
  ...(className === 'RefundClass' && {
    backgroundColor: '#DEEDFBD6 !important',
    color: '#0042EA !important',          
  }),
  ...(className === 'EnrolledClass' && {
    backgroundColor: '#D1FAE5 !important',
    color: '#059669 !Important',           
  }),
  ...(className === 'DiscountinuedClass' && {
    backgroundColor: '#E0E0E0 !important', 
    color: '#475569 !important',          
  }),
  fontWeight: 'bold',
}));


const TablePaper = styled(Paper)({
    width: "100%",
    overflowX: "auto",
    "& .table": {
      minWidth: 650,
    },
    "& .tableCellCentered": {
      display: "flex",
      alignItems: "center",
    },
    "& .borderedSpan":{
      border:"2px solid #ECECEE",
      whiteSpace: "nowrap",
      marginRight:"2px",
      marginLeft: "2px"
    },
    "& .statusActive": {
      background: "#CFFBE6",
      color: "#3EAA86",
      padding: "8px",
      borderRadius: "10px",
      textTransform:"uppercase",
      fontWeight:"bold",
      fontSize:"12px"
    },
    "& .MuiTableCell-head":{
      fontWeight:600
    },
    "& .MuiTableCell-root": {
      textAlign: "center",
      justifyContent: "center"
    },
    "& .tableCellExpanded": {
      background: "#F9FAFC",
    },
    "& .expandMore": {
      verticalAlign: "middle",
    },
    "& .viewAppBtn": {
        backgroundColor: "#FFFFFF",
        color: "#34D399",
        borderRadius: "4px",
        fontFamily: "Plus Jakarta Sans",
        fontWeight: 700,
        padding: "5px 8px",
        textTransform: "unset",
        fontSize: "10px",
        border: "1px solid #34D399"
      },
  });

const AppDetailsBox = styled(Box)(({ theme }) => ({
  fontFamily:'Plus Jakarta Sans',
  maxWidth:'362px',
  background:'#fff',
  padding:'20px',
  "& h6":{
    fontSize:'18px',
    "& p":{
      fontSize:'16px',
    }
  },
"& .grid-box-new,.MuiTypography-body1,.MuiTypography-body2":{
  fontSize:'14px',
  fontFamily:'Plus Jakarta Sans',
},
"& .recent-stepper .RefundStperClass":{
  background:'#F0F8FF !important',
  padding:'5px'
},
"& .recent-stepper .makeStyles-active-41":{
  marginRight:'6px !important',
  marginTop:'6px !important',
},
"& .recent-stepper .RefundStperClass .SteperHeading.RefundSteperHeading":{
  fontSize:'12px',
  fontWeight:'normal',
},
"& .recent-stepper .RefundStperClass .MuiStepLabel-alternativeLabel":{
  marginTop:'0'
},
"& .recent-stepper .RefundStperClass .makeStyles-root-40":{
  height:'16px',
  width:'16px',
},
"& .recent-stepper .RefundStperClass .MuiSvgIcon-root":{
  fontSize:'1rem',
},
"& .recent-stepper .RefundStperClass .MuiStepConnector-horizontal":{
  left: '15px !important',
},
"@media screen and (-webkit-min-device-pixel-ratio:0)": {
  "& .recent-stepper .RefundStperClass .MuiStepConnector-horizontal": {
    left: '18px !important',
  },
},
"& .recent-stepper .RefundStperClass .MuiStepConnector-lineHorizontal": {
  height: '37px',
},
"&.recent-stepper .enrolledStperClass .makeStyles-root-40.makeStyles-completed-42":{
  backgroundColor:'#34D399 !important',
}
}));

const CustomizeRadio = styled(Radio)({
  '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
  {
    color: '#34D399',
    width: 24,
    height: 24
  },
  '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
    color: '#34D399',
    width: 24,
    height: 24
  },
})

const LabelsTypography = styled(Typography)({
  "&.MuiTypography-root": {
    fontWeight: '700',
  },
  fontSize: "16px",
  lineHeight: "24px",
  fontFamily: "Plus Jakarta Sans",
})

const StarTxt = styled("span")({
  color: "black",
  fontFamily: "Plus Jakarta Sans"
});

const SelectInput = styled(Select)({
  width: "100%",
  borderRadius: "8px",
  border: "1px solid #CBD5E1",
  background: "white",
  height: "50px",
  display: "flex",
  margin: "5px 0px",
  justifyContent: "center",
  boxSizing: "border-box",
  padding: "0px 10px",
  "& .MuiSelect-icon": {
    color: "#0E172A",
    right: "5px"
  },
  "& .MuiInput-root": {
    "&::before": {
      content: "none"
    },
    "&:focus": {
      outline: "none"
    },
    "&::after": {
      content: "none"
    }
  },
  "&.MuiInput-underline:after": {
    borderBottom: "0px solid red !important"
  },
  "&.MuiInput-underline:before": {
    borderBottom: "0px solid red !important"
  },
  "&:hover:not(.Mui-disabled):before": {
    borderBottom: "0px solid red !important",
  },
  "&:hover:not(.Mui-disabled):after": {
    borderBottom: "0px solid red !important"
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent"
  },
  "& .MuiInputBase-input": {
    color: '#64748B',
    fontWeight: 700,
  },

});

const RequiredTxt = styled("span")({
  color: "red",
  fontFamily: "Plus Jakarta Sans",
  fontSize: "15px"
});

const VisaTravelModel = styled(Dialog)({
  "& .Container": {
    minWidth: "560px",
    padding: "20px 0px",
  },
  position: "relative",
  "& .LightcolorText": {
    color: "#64748B"
  },
  "& .CloseIconContainer": {
    display: "flex",
    justifyContent: 'flex-end',
    width: "100%",
    minHeight: "40px",
    position: "absolute",
  },
  "& .BoldTitle": {
    fontWeight: "bold"
  },
  "& .ModalHeadig": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '18px',
    fontWeight: '700',
    textTransform: "capitalize",
    marginBottom: "20px",
    textAlign: "center"
  },
  "& .ContantryAndDateClass": {
    display: "flex",
    gap: "5px",
    "& .Title": {
      marginTop: "2px !important",
      marginBottom: "2px !important"
    },
    "& .ReadMore": {
      marginLeft: "20px",
      "&:hover": {
        color: "red",
        cursor: "pointer",
      }
    }
  },
})

const UploadModalBox
 = styled(Dialog)({
  minWidth: "560px",
  position: "relative",
  "& .CloseIconContainer": {
    display: "flex",
    justifyContent: 'flex-end',
    width: "100%",
    minHeight: "40px"
  },
  "& .HeadingContainer": {
    display: "flex",
    justifyContent: "center"
  },
  "& .MuiDialogContent-root": {
    padding: "0px 40px",
    minWidth: "600px"
  },
  "& .ModalHeadig": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '18px',
    fontWeight: '700',
    textTransform: "capitalize",
    marginBottom: "20px",
    textAlign: "center"
  },
  "& .ModalSubHeadig": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: "30px"
  },
  "& .FileFormatAndFileSizeContainer": {
    padding: "10px",
    backgroundColor: "#E2E8F0",
    borderRadius: "30px",
    margin: "0 20px",
    marginBottom: "20px",
    border: "1px solid #E2E8F0",
    "& .FileFormatHeading": {
      fontFamily: "Plus Jakarta Sans",
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '20.16px',
      textAlign: 'center',
    },
    "& .FilesName": {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '20.16px',
      textAlign: 'center',
    }
  },
  "& .DragAndDropContainer": {
    display: "flex",
    justifyContent: "center",
    padding: "30px",
    marginTop: "10px",
    marginBottom: "10px",
    border: "2px dotted #CBD5E1 !important",
    "& .UploadButtonContainer": {
      display: "flex",
      justifyContent: "center",
    },
    "& .DragAndDropIconContainer": {
      display: "flex",
      justifyContent: 'center',
      marginBottom: "10px"
    },
    "& .DragAndDropheading": {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '16px',
      fontWeight: 400,
      textAlign: 'center'
    },
    "& .DragAndDropIcon": {
      minWidth: "60px",
      minHeight: "60px"
    },
  },
  "& .BgChange": {
    backgroundColor: "#F8FAFC",
  },
  "& .SaveChagesButtonContainer": {
    display: "flex",
    justifyContent: 'center',
    marginTop: "15px",
    paddingBottom: "30px"
  },
  "& .AddDocumentButton": {
    color: "#34D399",
    border: "1px solid #34D399",
    borderRadius: "6px",
    textTransform: "capitalize",
    backgroundColor: "white",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none"
    },
  },
  "& .DeleteButton": {
    color: "red",
    border: "1px solid #E2E8F0",
    backgroundColor: "#E2E8F0"
  },
  "& .SaveChangesButton": {
    color: "#fff",
    borderRadius: "6px",
    textTransform: "capitalize",
    boxShadow: "none",
    backgroundColor: "#34D399",
    "&:hover": {
      backgroundColor: "#34D399",
      boxShadow: "none"
    },
  },
  "& .InputHidden": {
    display: "none"
  },
  "& .RemarkContainer": {
    marginTop: "20px"
  },
  "& .RemarkHeading": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '18px',
    fontWeight: '700',
    textTransform: "capitalize",
    textAlign: "left"
  },
  "& .inputField": {
    backgroundColor: "#fff",
    width: "100%",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    margin: "5px 0px",
    padding: "0px 10px",
    boxSizing: "border-box",
    "& .MuiInput-root": {
      "&:focus": {
        outline: "none"
      },
      "&::before": {
        content: "none"
      },
      "&::after": {
        content: "none"
      }
    }
  },
  "& .RenderIcon": {
    display: "flex",
    justifyContent: 'center',
    "& .MuiSvgIcon-root": {
      fontSize: "60px"
    }
  }

});

const AssignedStaffTabStyle = styled(Box)({
  // mainclass
  "& .AssignedStaffHeading": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: '700',
    textTransform: "capitalize",
  },
  "& .SatffSubHeading": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: "capitalize",
    "@media (max-width: 600px)": {fontSize: '10px', },
  },
  // classes
  "& .AssignedStaffContainer": {
    padding: "20px"
  },
  "& .VisitonStaffContainer": {
    display:'flex',
    marginTop: "30px",
    "& .HeadingContainer": {
      marginBottom: "20px"
    },
    "& .CardOfAssignedStaff": {
      minWidth: '200px',
      minHeight: '200px',
      padding: '12px',
      borderRadius: '8px',
      "& .StaffLogoContainer": {
        display: "flex",
        justifyContent: 'center',
        "& .StaffLogo": {
          minWidth: "70px",
          minHeight: "70px",
          width: "70px",
          height: "70px",
          borderRadius: "50%",
        },
      },
      "& .FirstNameLastNameContainer": {
        marginTop: "10px",
        magrinBottom: "10px",
      },
      "& .TiltleContainer": {
      },
      "& .PhoneContainer": {
        "& .phonerNumber": {
          display: "flex",
          gap: "10px",
          justifyContent: 'center',
          alignItems: "center",
          "& a": {
            color: "#212121",
            textDecoration: "none"
          }
        }
      }
    }
  },
  "& .UniversityStaffContainer": {
    marginTop: "30px",
  },

  "& .TableContaier": {
    marginTop: "30px",
    "& .NoDataAvailable": {
      textAlign: "center"
    }
  }
})
const ApplicationsStylednEW = styled(Box)({
  "& .PipelineContainer":{
    background:'#F0FFF9 !important'
  },
  "& .pipeline-text":{
    color : '#64748B',
    fontSize:'12px',
    maxWidth:'90px',
    fontFamily: 'Plus Jakarta Sans',
  },
  "& h6":{
    color:'#000 !important',
  },
  "& .SteperHeading.RefundSteperHeading":{
    color:'#000 !important',
  },
  "& .MuiStepLabel-alternativeLabel":{
    marginTop:'14px',
    marginRight:'6px',
  },
  "& .recent-stepper .RefundStperClass":{
    bacground:'red'
  }
})
const ApplicationsStyled = styled(Box)({
  "& .RemovePadding": {
    // padding: "0"
  },
  "& .RemoveWhiteSpace": {
    whiteSpace: "noWrap"
  },
  "& .ApplicationsHeading": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: '700',
    textTransform: "capitalize",
  },
  "& .ApplicationsSubHeading": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: "capitalize",
  },
  "& .NoDataAvailable": {
    textAlign: "center"
  },
  "& .TableDataContaoner": {
    borderBottom: "none",
  },
  "& .RowContainer": {
    border: "1px solid #E2E8F0",
    position: "relative",
    "& .RemoveWhiteSpace": {
      whiteSpace: "noWrap"
    },
    "& .SmallText": {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '14px',
      fontWeight: '400',
      color: "#212121"
    },
    "& .InprogressClass": {
      backgroundColor: "#FEF3C7",
      color: "#D97706"
    },
    "& .ViewAppButtonCell": {
      paddingLeft: "80px",
      whiteSpace: "noWrap"
    },
    "& .ViewAppButton": {
      color: "#34D399",
      border: "1px solid #34D399",
      borderRadius: "6px",
      textTransform: "capitalize",
      backgroundColor: "white",
      boxShadow: "none",
      marginRight: "5px"
    },
    "& .PipelineContainer": {
      backgroundColor: "#F8FAFC",
      "& h6":{
        fontSize:'12px',
      },
      "& .StperClass": {
        padding: "0px !important",
        backgroundColor: "transparent",
      },
      "& .RefundStperClass": {
        width: "fit-content"
      },
      "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
        marginTop: "4px"
      },
      "& .MuiStepConnector-alternativeLabel": {
        left: "calc(-50% + 0px) !important",
        right: "calc(50% + 0px) !important"
      },
      "& .SteperHeading": {
        color: "#94A3B8B2",
        fontFamily: "Plus Jakarta Sans !important",
        fontSize: '12px !important',
        fontWeight: '700', textAlign: 'center',
      },
      "& .RefundSteperHeading": {
        color: "#0042EA",
        fontSize: '12px !important',
      }
    }
  }
})


const RefundlibConnector = withStyles({
  alternativeLabel: {
    top: 10,
  },
  active: {
    '& $line': {
      backgroundColor: '#0042EA',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#0042EA',
    },
  },
  line: {
    height: 7,
    border: 0,
    backgroundColor: '#D9D9D9',
    borderRadius: 1,
  },
})(StepConnector);

function RefundColorlibStepIcon(props: StepIconProps) {
  const classes = useRefundColorlibStepIconStyles();
  const { active, completed } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed || active ? (
        <CheckIcon />
      ) : (
        <div style={{ width: "10px", height: "10px", borderRadius: "50%" }} />
      )}
    </div>
  );
}

const useRefundColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
    zIndex: 1,
    color: "#fff",
    width: 24,
    height: 24,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #0042EA",
  },
  active: {
    backgroundColor: "#0042EA"
  },
  completed: {
    backgroundColor: "#0042EA"
  },
  "& .MuiStepLabel-alternativeLabel": {
    paddingLeft: "0px",
    paddingRight: "0px"
  },
});

const BoxWrapper = styled(Box)({
  "& .button-hide-bar":{
    display:'none',
    "@media (max-width: 768px)": {
      display: "block",
    left: "60px",
    position: "fixed",
    zIndex: 30090000,
    top: "38%",
    backgroundColor: "#059669 !important",
    color: "#fff",
    "&:hover": {
      backgroundColor: "darkred", 
      cursor: "pointer",       
    },
    }
  },
  "& .mainBar":{
    paddingLeft:'0px',
    "@media (max-width: 768px)": {
      paddingLeft:'72px',
    }
  },
  "& .mainBar-sidemenu":{
    marginLeft:'30px',
    "@media (max-width: 768px)": {
      position:'absolute',
      zIndex:999999,
      top:'13%',
      padding: '0 !important',
      marginLeft: '80px',
    }
  },
  "& .PersonalInfoGridContainer":{
    width:'100%',
    "@media (max-width: 768px)": {
      width:'100%',
    }
  },
  "& .table-appId":{
    maxWidth:'100%',
    "@media (max-width: 768px)": {
      maxWidth:'765px',
    },
    "@media (max-width: 568px)": {
      maxWidth:'560px',
    },
    "@media (max-width: 360px)": {
      maxWidth:'350px',
    }
  },
  "& .StundetGenralDetails":{
    maxWidth:'100%',
    "@media (max-width: 768px)": {
      maxWidth:'765px',
    },
    "@media (max-width: 568px)": {
      maxWidth:'560px',
    },
    "@media (max-width: 360px)": {
      maxWidth:'350px',
    }
  },
  "& .StudentDetails-tabss":{
    "@media (max-width: 1208px)": {
      maxWidth:'700px',
    },
    "@media (max-width: 768px)": {
      maxWidth:'500px',
    },
    "@media (max-width: 568px)": {
      maxWidth:'411px',
    },
    "@media (max-width: 360px)": {
      maxWidth:'300px',
    }
  },
  "& .MuiExpansionPanelSummary-content":{
    display:'flex',
    "@media (max-width: 768px)": {
      flexDirection:'column',
    },
  },
  "& .MuiTab-textColorPrimary":{
    "@media (max-width: 1280px)": {
      maxWidth:'187px',
      minWidth:'100px',
    },
    "@media (max-width: 450px)": {
      maxWidth:'60px',
      minWidth:'60px',
    },
  }
})

const ModalContent = styled(Box)({
  position: "relative",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: "24px",
  flexShrink: 0,
  borderRadius: "8px",
  backgroundColor: "#fff",
  width: "90%",
  maxWidth: "448px",
  height: "auto",
  minHeight: "575px",
  boxShadow: "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
  margin: "auto",
  "@media (min-width: 600px)": {
    width: "80%",
    maxWidth: "500px",
  },
  "@media (min-width: 960px)": {
    width: "70%",
    maxWidth: "600px",
  },
  "@media (min-width: 1280px)": {
    width: "60%",
    maxWidth: "600px",
  },
});

const BoxPopupClose = styled(Box)({
  display: "flex",
  padding: "4px",
  alignItems: "flex-start",
  gap: "10px",
  position: "absolute",
  right: "16px",
  top: "16px",
  "@media (min-width: 600px)": {
    right: "14px",
    top: "14px"
  },
  "@media (min-width: 960px)": {
    right: "12px",
    top: "12px"
  }
});

const BoxPopUpTitle = styled(Box)({
  width: "94px",
  height: "32px",
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  "@media (min-width: 600px)": {
    width: "84px",
    height: "28px",
  },
  "@media (min-width: 960px)": {
    width: "74px",
    height: "24px",
  }
});

const MenuListItems = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "16px",
  "& .cardLogoImg": {
    height: "64px",
    width: "64px",
    borderRadius: "50%",
    backgroundColor: "#F8FAFC",
    objectFit: "cover",
    display: "block"
  },
  "& .listContentBox": {
    display: "flex",
  },
  "& .listContentTitle": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#0F172A",
    minWidth: "120px"
  },
  "& .listContentValue": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#0F172A"
  }
})

const StyledBoxButtonWrapper = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
  gap: "24px",
  "@media (min-width: 600px)": {
    gap: "20px"
  },
  "@media (min-width: 960px)": {
    gap: "16px"
  }
});


const StyledApplyFilterButton = styled(Button)({
  display: "flex",
  padding: "10px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  borderRadius: "8px",
  backgroundColor: "#34D399 !important",
  "&:hover": {
    backgroundColor: "#34D399 !important"
  },
  "@media (min-width: 600px)": {
    padding: "8px 14px"
  },
  "@media (min-width: 960px)": {
    padding: "6px 12px"
  }
});

const StyledTypoGraphyApply = styled(Typography)({
  color: "var(--Basic-White, var(--0, #FFF))",
  fontFamily: "Plus Jakarta Sans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "24px",
  textTransform: "capitalize"
});

const FilterModalSubTitle = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "16px !important",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#000000",
})

const ModaInnerBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  maxWidth: "400px",
  borderRadius: "8px",
  backgroundColor: "#fff",
  padding: "20px",
  boxSizing: "border-box",
  "& .filterTitle": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    color: "var(--Basic-Black, #000)",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px"
  },
  "& .insideBox": {
    width: "100%",
    padding: "16px 30px",
    "& .innerContent": {
      paddingTop: "16px"
    }
  },
  "& .inputTxt": {
    fontSize: "12px",
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 700,
    color: "#334155"
  },
  "& .selectInput": {
    margin: "5px 0px",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "#fff",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    height: "auto",
    padding: "0px 10px",
    boxSizing: "border-box",

    "& .MuiInput-root": {
      "&:focus": {
        outline: "none"
      },
      "&::before": {
        content: "none"
      },
      "&::after": {
        content: "none"
      }
    },
    "& .MuiSelect-icon": {
      color: "#0E172A",
      right: "5px"
    },
    "&.MuiInput-underline:before": {
      borderBottom: "0px solid red"
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px solid red"
    },
    "&:hover:not(.Mui-disabled):after": {
      borderBottom: "0px solid red"
    },
    "&.MuiInput-underline:after": {
      borderBottom: "0px solid red"
    },
    "& .MuiInput-input": {
      padding: "10px"
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent"
    }
  },
  "& .autocompleteField": {
    "& input": {
      marginTop: "10px"
    }
  },
  "@media (min-width: 600px)": {
    width: "90%",
    maxWidth: "400px",
    padding: "30px",
    "& .filterTitle": {
      fontSize: "20px",
      lineHeight: "24px"
    },
    "& .insideBox": {
      padding: "20px 40px",
      "& .innerContent": {
        paddingTop: "20px"
      }
    },
    "& .inputTxt": {
      fontSize: "14px"
    },
  },
  "@media (min-width: 960px)": {
    width: "80%",
    maxWidth: "400px",
    padding: "40px 0",
    "& .filterTitle": {
      fontSize: "22px"
    },
    "& .insideBox": {
      padding: "20px 50px",
      "& .innerContent": {
        paddingTop: "24px"
      }
    },
    "& .inputTxt": {
      fontSize: "16px"
    },
  },
  "@media (min-width: 1280px)": {
    width: "80%",
    maxWidth: "500px",
    padding: "50px 0",
    "& .filterTitle": {
      fontSize: "24px",
      lineHeight: "30px"
    },
    "& .insideBox": {
      padding: "50px 0",
      "& .innerContent": {
        paddingTop: "24px"
      }
    },
    "& .inputTxt": {
      fontSize: "18px"
    },
  },
});
const FilterModalTitle = styled(Typography)({
  color: "var(--Basic - Black, #000)",
  fontFamily: "Plus Jakarta Sans",
  fontSize: "20px !important",
  fontStyle: "normal",
  lineHeight: '24px'
})
// Customizable Area End