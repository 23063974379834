// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { runEngine } from "framework/src/RunEngine";
import { Message } from "framework/src/Message";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { getStorageData } from "framework/src/Utilities";
import { toast } from "react-toastify";
// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  sendDataCount?: (data: number) => void;
  indexValue?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loader: boolean,
  MainTextFildevalue: string,
  ReplyTextFildevalue: string,
  EmojiOpenIndex: number,
  MainEmoji: boolean,
  ReplyTextBoxIndex: number,
  ChatData: Array<{
    id: string,
    type: string,
    commentId: string,
    admin_name: string,
    comment: string,
    sent_to: string,
    created_at: string,
    updated_at: string,
    full_name: string,
    ProfilePic: string,
    Files: Array<{
      id: string,
      filename: string,
      content_type: string,
      file_url: string
    }>,
    conversation_replies: Array<{
      id: string,
      reply: string,
      ReplyToName: string,
      ReplyToUserType: string,
      ReplyToProfilePicture: string,
      ReplyByName: string,
      ReplyByProfilePicture: string,
      ReplyByUserType: string,
      created_at: string,
      updated_at: string,
      conversation_files: Array<{
        id: string,
        filename: string,
        file_url: string
      }>
      created_date: string,
      created_time: string,
      updated_date: string,
      updated_time: string,
    }>
  }>,
  userDetails: {
    attributes: {
      first_name: string;
      role: string;
      last_name: string,
      image: { url: string },
    },
  },
  LoadMoreCont: number,
  MainAttchmentFiles: File[],
  ReplyAttchmentFiles: File[],
  DeleteModel: boolean,
  DeleteModelId: string,
  IsDeleteReplyOrComment: string,
  EditEmojiModelState: boolean,
  EditTextBoxValue: string,
  EditMainMessegeModel: boolean,
  IsItReplyOrChatEdit: string,
  UpDateId: string,
  CreteReply: {
    commentId: string,
    full_name: string,
  },
  EditRenderFilesData: Array<{
    id: string,
    filename: string,
    file_url: string
  }>
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: string;
  // Customizable Area End
}
// Customizable Area Start

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}


type AttachedFile = {
  id: string;
  filename: string;
  content_type: string;
  file_url: string;
};

type ReplyTo = {
  name: string;
  profile_picture: string;
  user_type: string;
};

type ReplyBy = {
  name: string;
  admin_profile_picture: string;
  user_type: string;
};

type ConversationFile = {
  id: string;
  file_name: string;
  file_url: string;
};

type ConversationReply = {
  id: string;
  reply: string;
  reply_to: ReplyTo;
  reply_by: ReplyBy;
  created_at: string;
  updated_at: string;
  conversation_files: ConversationFile[];
  created_date: string;
  created_time: string;
  updated_date: string;
  updated_time: string;
  admin_profile_pic: string;
};

type CommentAttributes = {
  id: string;
  comment: string;
  sent_to: string;
  created_at: string;
  admin_name: string;
  updated_at: string;
  full_name: string;
  profile_pic: string;
  attached_files: AttachedFile[];
  conversation_replies: ConversationReply[];
};

type CommentData = {
  id: string;
  type: string;
  attributes: CommentAttributes;
};

type ConversationResponseApi = {
  data: CommentData[];
};

export const configJSON = require("./config");
// Customizable Area End

export default class ApplicationsConversationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProfileAPICallId: string = "";
  GetChatDataApiId: string = "";
  CreateConvertionApiCallId: string = ""
  ReplyConvertionApiCallId: string = ""
  UpdateConversationApiCallId: string = ""
  UpdateConversationReplyApiCallId: string = ""
  DeleteCommnetApiCallId: string = ""
  DeleteReplyApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      loader: false,
      MainTextFildevalue: "",
      ReplyTextFildevalue: "",
      EmojiOpenIndex: -1,
      ReplyTextBoxIndex: -1,
      ChatData: [],
      userDetails: {
        attributes: {
          first_name: '',
          role: '',
          last_name: '',
          image: {
            url: '',
          },
        },
      },
      MainEmoji: false,
      LoadMoreCont: 5,
      MainAttchmentFiles: [],
      ReplyAttchmentFiles: [],
      DeleteModel: false,
      DeleteModelId: "",
      IsDeleteReplyOrComment: "",
      EditEmojiModelState: false,
      EditTextBoxValue: "",
      EditMainMessegeModel: false,
      IsItReplyOrChatEdit: "",
      UpDateId: "",
      CreteReply: {
        commentId: "",
        full_name: "",
      },
      EditRenderFilesData: []
    };

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getProfileAPICallId) {
          this.setState({ userDetails: responseJson.data });
        }
        this.GetConvertionApiResponse(apiRequestCallId, responseJson)
        this.CreateConvertionApiCallResponse(apiRequestCallId, responseJson)
        this.ReplyConvertionApiCallResponse(apiRequestCallId, responseJson)
        this.DeleteApiCallResponse(apiRequestCallId, responseJson)
        this.UpdateConversationApiCallResponse(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getFormApprovalProfile()
    this.GetConvertionApiFuncation()
  }

  ApplicationsConversationApiCall = async (apiData: APIPayloadType) => {
    const { contentType, method, body, type, endPoint } = apiData;
    let token = await getStorageData("token")
    const header = {
      "Content-Type": contentType,
      token: token
    };
    const requestMessageForViewMore = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageForViewMore.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageForViewMore.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageForViewMore.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && type !== "formData"
      ? requestMessageForViewMore.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessageForViewMore.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );

    runEngine.sendMessage(requestMessageForViewMore.id, requestMessageForViewMore);

    return requestMessageForViewMore.messageId;
  };

  getFormApprovalProfile = async () => {
    let userDetails = await getStorageData("userDetails")
    this.getProfileAPICallId = await this.ApplicationsConversationApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getProfileEndPoint + userDetails
    });
  }
  GetConvertionApiFuncation = async () => {
    let dataLocal = JSON.parse(await getStorageData("ViewAppData"))
    this.setState({
      loader: true
    })
    this.GetChatDataApiId = await this.ApplicationsConversationApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: "/bx_block_conversationthreading2/comments/show_child_comments?student_application_id=" + dataLocal.id
    });
  }

  GetConvertionApiResponse = (ApiCallId: string, ApiResponse: ConversationResponseApi) => {
    if (ApiCallId === this.GetChatDataApiId) {
      const data = ApiResponse.data.map((item) => {
        return {
          id: `${item.id}`,
          commentId: `${item.attributes.id}`,
          admin_name: `${item.attributes.admin_name}`,
          type: item.type,
          comment: `${item.attributes.comment}`,
          sent_to: `${item.attributes.sent_to}`,
          created_at: `${item.attributes.created_at}`,
          updated_at: `${item.attributes.updated_at}`,
          full_name: `${item.attributes.full_name}`,
          ProfilePic: `${item.attributes.profile_pic}`,
          Files: item.attributes.attached_files.map((FileItem) => {
            return {
              id: FileItem.id,
              filename: FileItem.filename,
              content_type: FileItem.content_type,
              file_url: FileItem.file_url
            }
          }),
          conversation_replies: item.attributes?.conversation_replies?.map((ConversationData) => {
            return {
              id: `${ConversationData.id}`,
              reply: `${ConversationData.reply}`,
              ReplyToName: `${ConversationData.reply_to.name}`,
              ReplyToUserType: `${ConversationData.admin_profile_pic}`,
              ReplyToProfilePicture: `${ConversationData.reply_to.user_type}`,
              ReplyByName: `${ConversationData.reply_by.name}`,
              ReplyByProfilePicture: `${ConversationData.admin_profile_pic}`,
              ReplyByUserType: `${ConversationData.reply_by.user_type}`,
              created_at: ConversationData.created_at,
              updated_at: ConversationData.updated_at,
              conversation_files: ConversationData?.conversation_files?.map((Subitem) => {
                return {
                  id: Subitem.id,
                  filename: Subitem.file_name,
                  file_url: Subitem.file_url,
                }
              }),
              created_date: ConversationData.created_date,
              created_time: ConversationData.created_time,
              updated_date: ConversationData.updated_date,
              updated_time: ConversationData.updated_time,
            }
          })
        }
      })
      this.setState({ ChatData: data, loader: false }, () => {
        if (this.props.sendDataCount) {
          this.props.sendDataCount(this.state.ChatData.length)
        }
      });
    }
  }
  handleLoadMore = () => {
    this.setState({
      LoadMoreCont: Math.min(this.state.LoadMoreCont + 5, this.state.ChatData.length)
    })
  };

  ReplyButtonHandel = (data: { id: string, conversation_replies: Array<{}>, commentId: string, full_name: string }) => {
    const { conversation_replies } = data;
    if (conversation_replies.length === 0) {
      this.setState({
        EditMainMessegeModel: true,
        IsItReplyOrChatEdit: "CreateReply",
        CreteReply: {
          commentId: data.commentId,
          full_name: data.full_name,
        }
      })
    } else {
      this.setState({
        ReplyTextBoxIndex: this.state.ReplyTextBoxIndex === Number(data.id) ? -1 : Number(data.id),
        EmojiOpenIndex: -1,
        MainEmoji: false,
        ReplyTextFildevalue: "",
      })
    }
  }

  MainTextFieldEmojiHande = (values: string) => {
    this.setState({
      MainTextFildevalue: this.state.MainTextFildevalue.concat(values)
    })
  }

  ReplyEmojiOnClick = (item: { id: string }) => {
    this.setState({
      EmojiOpenIndex: this.state.EmojiOpenIndex === Number(item.id) ? -1 : Number(item.id),
      MainEmoji: false,
    })
  }

  MainTextFieldOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      MainTextFildevalue: e.target.value
    })
  }

  ReplyTextFieldOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      ReplyTextFildevalue: e.target.value
    })
  }

  ReplyEmojiHandelChange = (value: string) => {
    this.setState({
      ReplyTextFildevalue: this.state.ReplyTextFildevalue.concat(value)
    })
  }

  MainTextFieldEmojiOnclick = () => {
    this.setState({
      MainEmoji: !this.state.MainEmoji,
      EmojiOpenIndex: -1
    })
  }

  CreateConvertionApiCall = async () => {
    if (this.state.MainTextFildevalue !== "" || this.state.MainAttchmentFiles.length !== 0) {
      let dataLocal = JSON.parse(await getStorageData("ViewAppData"))
      const formdata = new FormData();
      this.state.MainAttchmentFiles.forEach((file) => {
        formdata.append("files[]", file);
      });
      formdata.append("comment", this.state.MainTextFildevalue);
      formdata.append("student_application_id", dataLocal.id);
      this.CreateConvertionApiCallId = await this.ApplicationsConversationApiCall({
        method: configJSON.exampleAPiMethod,
        endPoint: "/bx_block_conversationthreading2/comments",
        body: formdata,
        type: "formData",
      });
    }
  }
  CreateConvertionApiCallResponse = (ApiCallId: string, _responseJson: {}) => {
    if (ApiCallId === this.CreateConvertionApiCallId) {
      this.GetConvertionApiFuncation()
      this.setState({
        MainTextFildevalue: "",
        MainAttchmentFiles: [],
        MainEmoji: false,
      })
    }
  }

  ReplyConvertionApiCall = async (commentId: string, fullname: string, reply: string) => {
    if (this.state.ReplyTextFildevalue !== "" || this.state.EditTextBoxValue !== "" || this.state.ReplyAttchmentFiles.length !== 0) {
      const formdata = new FormData();
      formdata.append("reply", reply);
      formdata.append("reply_to", fullname);
      formdata.append("comment_id", commentId);
      this.state.ReplyAttchmentFiles.forEach((file) => {
        formdata.append("conversation_files[]", file);
      });
      this.ReplyConvertionApiCallId = await this.ApplicationsConversationApiCall({
        method: configJSON.exampleAPiMethod,
        endPoint: "/bx_block_conversationthreading2/comments/create_reply",
        body: formdata,
        type: "formData",
      });
    }
  }

  ReplyConvertionApiCallResponse = (ApiCallId: string, _responseJson: {}) => {
    if (ApiCallId === this.ReplyConvertionApiCallId) {
      this.GetConvertionApiFuncation()
      this.setState({
        ReplyTextFildevalue: "",
        ReplyAttchmentFiles: [],
        EditTextBoxValue: "",
        EditMainMessegeModel: false,
        IsItReplyOrChatEdit: "",
        CreteReply: {
          commentId: "",
          full_name: "",
        },
        EditEmojiModelState: false,
        EditRenderFilesData: [],
        EmojiOpenIndex: -1,
      })
      this.HandeCloseEditMainMessegeModel()
    }
  }

  isAcceptedFormat = (fileExtension?: string): boolean => {
    const acceptedFormats = ['png', 'jpg', 'pdf', 'doc', 'docx'];
    return fileExtension ? acceptedFormats.includes(fileExtension) : false;
  };

  getFileExtension = (file: File): string | undefined => {
    return file.type.split('/')[1]?.toLowerCase();
  };

  MaxFileSize = () => {
    const maxFileSize: number = 10 * 1024 * 1024;
    return maxFileSize;
  }

  UploadAttchmentFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const FilesArray = Array.from(files);
      const validFiles: File[] = [];
      FilesArray.map((i) => i).map((UploadedFile) => {
        if (!this.isAcceptedFormat(this.getFileExtension(UploadedFile))) {
          toast.error(`${UploadedFile.name} is not an accepted file type.`);
        } else if (UploadedFile.size > this.MaxFileSize()) {
          toast.error(`${UploadedFile.name} is too large. Maximum size is 10 MB.`)
        } else {
          toast.success(`File:${UploadedFile.name.split('.')[0]}  Uploaded Successfully`);
          validFiles.push(UploadedFile);
          this.setState({
            MainAttchmentFiles: validFiles,
          });
        }
      });
    }
  }

  ReplyUploadAttchmentFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const FilesArray = Array.from(files);
      const validFiles: File[] = [];
      FilesArray.map((i) => i).map((UploadedFile) => {
        if (!this.isAcceptedFormat(this.getFileExtension(UploadedFile))) {
          toast.error(`${UploadedFile.name} is not an accepted file type.`);
        } else if (UploadedFile.size > this.MaxFileSize()) {
          toast.error(`${UploadedFile.name} is too large. Maximum size is 10 MB.`)
        } else {
          toast.success(`File:${UploadedFile.name.split('.')[0]}  Uploaded Successfully`);
          validFiles.push(UploadedFile);
          this.setState({
            ReplyAttchmentFiles: this.state.ReplyAttchmentFiles.concat(validFiles),
          });
        }
      });
    }
  }

  DeleteFuncation = () => {
    const { IsDeleteReplyOrComment } = this.state
    if (IsDeleteReplyOrComment === "Reply") {
      this.DeleteReplyApiCallFuncation()
    } else {
      this.DeleteApiCallFuncation()
    }
  }

  DelteModelOpenAndClose = () => {
    this.setState({
      DeleteModel: !this.state.DeleteModel
    })
  }

  DeleteModelOpenFun = (id: string, IsDeleteReplyOrComment: string) => {
    this.setState({
      DeleteModel: true,
      DeleteModelId: id,
      IsDeleteReplyOrComment: IsDeleteReplyOrComment
    })
  }

  DeleteApiCallFuncation = async () => {
    this.setState({
      loader: true
    })
    this.DeleteCommnetApiCallId = await this.ApplicationsConversationApiCall({
      method: "DELETE",
      endPoint: "/bx_block_conversationthreading2/comments/delete_conversation?comment_id=" + this.state.DeleteModelId,
    });
  }

  DeleteReplyApiCallFuncation = async () => {
    this.setState({
      loader: true
    })
    this.DeleteReplyApiCallId = await this.ApplicationsConversationApiCall({
      method: "DELETE",
      endPoint: "/bx_block_conversationthreading2/comments/delete_conversation_reply?conversation_reply_id=" + this.state.DeleteModelId,
    });
  }

  DeleteApiCallResponse = (ApiCallId: string, _responseJson: {}) => {
    if (ApiCallId === this.DeleteCommnetApiCallId) {
      this.GetConvertionApiFuncation()
      this.setState({
        DeleteModel: false,
        loader: false,
        DeleteModelId: ""
      })
    }
    if (ApiCallId === this.DeleteReplyApiCallId) {
      this.GetConvertionApiFuncation()
      this.setState({
        DeleteModel: false,
        loader: false,
        DeleteModelId: ""
      })
    }
  }

  OpenAndCloseEmojiInEditModel = () => {
    this.setState({
      EditEmojiModelState: !this.state.EditEmojiModelState
    })
  }

  HandeCloseEditMainMessegeModel = () => {
    this.setState({
      EditMainMessegeModel: false,
      EditEmojiModelState: false,
      EditTextBoxValue: "",
      EditRenderFilesData: [],
      ReplyAttchmentFiles: []
    })
  }
  EditMainMessegeModelFuncation = (value: string, IsItChatOrReply: string, UpDateId: string, EditRenderFilesData: Array<{
    id: string,
    filename: string,
    file_url: string
  }>) => {
    this.setState({
      EditMainMessegeModel: true,
      EditTextBoxValue: value,
      IsItReplyOrChatEdit: IsItChatOrReply,
      UpDateId: UpDateId,
      EditRenderFilesData: EditRenderFilesData
    })
  }

  EditReplyTextFieldOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      EditTextBoxValue: e.target.value
    })
  }

  EditReplyEmojiHandelChange = (value: string) => {
    this.setState({
      EditTextBoxValue: this.state.EditTextBoxValue.concat(value)
    })
  }

  EditSaveButton = () => {
    if (this.state.IsItReplyOrChatEdit === "Chat") {
      this.UpdateConversationApiCallFucation()
    }
    if (this.state.IsItReplyOrChatEdit === "CreateReply") {
      this.ReplyConvertionApiCall(this.state.CreteReply.commentId, this.state.CreteReply.full_name, this.state.EditTextBoxValue)
    }
    if (this.state.IsItReplyOrChatEdit === "Reply") {
      this.UpdateConversationReplyApiCallFuncation()
    }
  }

  UpdateConversationApiCallFucation = async () => {
    const formdata = new FormData();
    formdata.append("comment", this.state.EditTextBoxValue);
    this.state.ReplyAttchmentFiles.forEach((file) => {
      formdata.append("files[]", file);
    });
    this.UpdateConversationApiCallId = await this.ApplicationsConversationApiCall({
      method: "PUT",
      endPoint: "/bx_block_conversationthreading2/comments/update_conversation?comment_id=" + this.state.UpDateId,
      body: formdata,
      type: "formData",
    });
  }

  UpdateConversationReplyApiCallFuncation = async () => {
    const formdata = new FormData();
    formdata.append("reply", this.state.EditTextBoxValue);
    this.state.ReplyAttchmentFiles.forEach((file) => {
      formdata.append("conversation_files[]", file);
    });
    this.UpdateConversationReplyApiCallId = await this.ApplicationsConversationApiCall({
      method: "PUT",
      endPoint: "/bx_block_conversationthreading2/comments/update_conversation_reply?conversation_reply_id=" + this.state.UpDateId,
      body: formdata,
      type: "formData",
    });
  }

  UpdateConversationApiCallResponse = (ApiCallId: string, _responseJson: {}) => {
    if (ApiCallId === this.UpdateConversationApiCallId) {
      this.GetConvertionApiFuncation()
      this.setState({
        EditMainMessegeModel: false,
        EditEmojiModelState: false,
        EditTextBoxValue: "",
      })
      this.HandeCloseEditMainMessegeModel()
    }
    if (ApiCallId === this.UpdateConversationReplyApiCallId) {
      this.GetConvertionApiFuncation()
      this.setState({
        EditMainMessegeModel: false,
        EditEmojiModelState: false,
        EditTextBoxValue: ""
      })
      this.HandeCloseEditMainMessegeModel()
    }
  }

  // Customizable Area End
}